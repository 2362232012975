import { environment } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { CoreModule } from './core/core.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import * as firebase from 'firebase/app';
import { WindowRef } from './core/constants/windowRef';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { NgxFileDropModule } from 'ngx-file-drop';
import { LottieModule } from 'ngx-lottie';

firebase.initializeApp(environment.FIREBASE);

const playerFactory = () => import('lottie-web');
@NgModule({
  declarations: [ AppComponent ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    CoreModule,
    NgxPermissionsModule.forRoot(),
    NgxBarcode6Module,
    NgxFileDropModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [ WindowRef ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
