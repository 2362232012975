import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: [ './image-preview.component.scss' ]
})
export class ImagePreviewComponent implements OnInit {

  imgPath: string;
  constructor (
    public dialogRef: MatDialogRef<ImagePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit (): void {
    this.imgPath = this.data;
  }

  close () {
    this.dialogRef.close();
  }

}
