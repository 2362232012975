import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, takeWhile, tap } from 'rxjs/internal/operators';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { DataService } from '../data/data.service';
import { ERROR_MESSAGES } from '../../constants/shared-constant';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  public loginAuthorization = null;

  constructor (
    private http: HttpClient,
    private authService: AuthService,
    private dataService: DataService
  ) { }

  cleanUrl (url) {
    if (url && url.includes('//')) {
      url = url.replace('//', '/');
    }
    return `${environment.base_url}${url}`;
  }

  post (url: string, body?: any, options?: any): Observable<Object> {
    url = this.cleanUrl(url);
    return this.authInterceptor(
      this.http.post(
        url,
        this.getRequestData(body),
        this.appendAuthHeader(options)
      ),
      'POST'
    );
  }

  get (
    url: string,
    optionsToUrl?: any,
    optionsToParams?: any,
    toUrlParam = false
  ): Observable<Object | Array<Object>> {
    return this.authInterceptor(
      this.http.get(
        this.toQueryString(url, optionsToUrl, toUrlParam),
        this.appendAuthHeader(optionsToParams)
      )
    );
  }

  exportCsv (url: string, params?: any, removeGlobalFilter?: boolean) {
    url = this.cleanUrl(url);
    const headers = this.appendAuthHeader('responseType');
    headers[ 'responseType' ] = 'blob';
    return this.http.post(
      url,
      this.getRequestData(
        {
          filterRequired: true,
          hidePagination: true,
          removeGlobalFilter: removeGlobalFilter
        },
        params
      ),
      headers
    );
  }

  getCsv(url: string) {
    url = this.cleanUrl(url);
    const headers = this.appendAuthHeader('responseType');
    headers[ 'responseType' ] = 'blob';
    headers['responseType'] = 'blob';
    return this.http.get(url, headers);
  }

  downloadPdf (url: string) {
    url = this.cleanUrl(url);
    const headers = this.appendAuthHeader('responseType');
    headers[ 'responseType' ] = 'blob';
    return this.http.get(url, headers);
  }

  put (
    url: string,
    body?: any,
    options?: any,
    toUrlParam = false
  ): Observable<Object> {
    return this.authInterceptor(
      this.http.put(
        this.toQueryString(url, options, toUrlParam),
        body,
        this.appendAuthHeader()
      ),
      'PUT'
    );
  }

  putWithHeaders(
    url: string,
    body?: any,
    options?: any,
    toUrlParam = false,
    noUrl = false,
  ): Observable<Object> {
    let httpOptions = this.appendAuthHeader();
    return this.authInterceptor(
      this.http.put(
        this.toQueryString(url, options, toUrlParam, noUrl),
        body,
        { ...httpOptions, headers: {...(options.headers || {}), ...httpOptions.headers} }
      ),
      'PUT'
    );
  }

  patch (
    url: string,
    body?: any,
    options?: any,
    toUrlParam = false
  ): Observable<object> {
    if (toUrlParam) {
      url = `${url}/${options}`;
      options = null;
    }
    url = this.cleanUrl(url);
    return this.authInterceptor(
      this.http.patch(url, body, this.appendAuthHeader(options)),
      'PATCH'
    );
  }

  delete (
    url: string,
    options?: any,
    toUrlParam = false,
    optionParam = null,
    body?: any
  ): Observable<Object> {
    return this.authInterceptor(
      this.http.delete(
        this.toQueryString(url, options, toUrlParam),
        this.appendAuthHeader(optionParam, body)
      ),
      'DELETE'
    );
  }

  toQueryString (url: string, options: any, toUrlParam: any, noUrl = false) {
    if (toUrlParam) {
      if (options) {
        url = `${url}/${options}`;
      }
      options = null;
    }
    if (noUrl) {
      return `${url}`;
    }
    return this.cleanUrl(url);;
  }

  private getRequestData (body: any, params = {}) {
    if (body && body.filterRequired) {
      let filterParams = {
        ...this.dataService.searchFilters.filters,
        ...params
      };
      if (!body.removeGlobalFilter) {
        filterParams = {
          ...this.dataService.globalFilter.apiValue,
          ...filterParams
        };
      }
      if(body.onlyCityIdRequired) {
        delete filterParams.hubId;
      }
      if (body.onlyHubIdRequired) {
        delete filterParams.cityId;
      }
      return body.hidePagination
        ? filterParams
        : {
          ...this.dataService.paginationData,
          ...{
            filters: filterParams
          }
        };
    } else {
      return body;
    }
  }

  private appendAuthHeader (options?: any, body?: any) {
    if (options && options.hideLoader) {
      this.dataService.hideLoader();
      delete options.hideLoader;
    } else {
      this.dataService.showLoader();
    }

    this.loginAuthorization = this.authService.getAuthentication();
    if (options && options === 'responseType') {
      const headers = new HttpHeaders({
        'mb-auth-key': this.loginAuthorization.token
      });
      headers.append('observe', 'response');
      return { headers: headers };
    }
    const httpOptions = {
      headers: new HttpHeaders(),
      params: null,
      body: null
    };

    if (this.loginAuthorization && this.loginAuthorization.token) {
      httpOptions.headers = httpOptions.headers.append(
        'mb-auth-key',
        this.loginAuthorization.token
      );
      this.loginAuthorization = null;
    }
    if (options) {
      httpOptions.params = options;
    }
    if (body) {
      httpOptions.body = body;
    }
    return httpOptions;
  }

  private authInterceptor (
    observable: Observable<Object>,
    whichMethod?: string
  ): Observable<Object> {
    return observable.pipe(
      takeWhile(() => {
        return this.dataService.isSubscriptionAlive;
      }),
      tap(h => {
        this.handleSuccess(h, whichMethod);
        this.dataService.hideLoader();
        if (
          h &&
          h[ 'errors' ] &&
          h[ 'errors' ].length > 0 &&
          h[ 'errors' ].status !== 404
        ) {
          if (h[ 'errors' ].status >= 500) {
            this.dataService.openSnackBar(
              'Check your Internet Connection / Internal Server Error'
            );
          } else {
            this.dataService.openSnackBar(h[ 'errors' ][ 0 ].message);
          }
        }
      }),
      catchError(this.handleError(`error`))
    );
  }

  private handleSuccess<T> (success: any, whichMethod: string) {
    if ((success === null || typeof success === 'number') && whichMethod) {
      switch (whichMethod) {
        case 'POST':
          this.dataService.openSnackBar(
            'Record Created Successfully',
            'success'
          );
          break;
        case 'PUT':
          this.dataService.openSnackBar(
            'Record Updated Successfully',
            'success'
          );
          break;
        case 'DELETE':
          this.dataService.openSnackBar('Record DELETED Successfully');
          break;
        case 'PATCH':
          this.dataService.openSnackBar(
            'Record Updated Successfully',
            'success'
          );
          break;
      }
    }
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.dataService.hideLoader();
      if (
        error &&
        error.error &&
        error.error[ 'errors' ] &&
        error.error[ 'errors' ].length > 0
      ) {
        let errorMessage = '';
        let count = 0;
        error.error[ 'errors' ].forEach(err => {
          count++;
          if (err.message === 'INVALID_REQUEST') {
            err.message = err.field + ' is invalid';
          }
          if (err.message === 'PRODUCT_CATALOG_DELETE_ERROR' || err.message === ERROR_MESSAGES.SCHEDULE_NOT_ALLOWED) {
            err.message = err.field;
          }
          if (!err.message && err.code) {
            err.message = err.code;
          }
          errorMessage =
            errorMessage + (count + ') ' + err.message + '.') + '<br>';
        });
        if (error.status >= 500) {
          this.dataService.openSnackBar(
            'Check your Internet Connection / Internal Server Error'
          );
        } else if (error.status === 401) {
          this.dataService.openSnackBar(errorMessage);
          this.authService.logOut();
        } else if (error.status !== 404) {
          this.dataService.openSnackBar(errorMessage);
        }
      }
      return of(result as T);
    };
  }
}
