import { MbPassLedgerComponent } from './../customer/containers/mb-pass/ledger/mb-pass-ledger.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from './components/input/input.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { RadioComponent } from './components/radio/radio.component';
import { DateComponent } from './components/date/date.component';
import { ErrorComponent } from './components/error/error.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { CrudComponent } from './containers/crud/crud.component';
import { FileComponent } from './components/file/file.component';
import { MediaComponent } from './components/media/media.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { AppMaterialModule } from './modules/app-material.module';

import { ListViewComponent } from './containers/list-view/list-view.component';
import { SearchComponent } from './containers/search/search.component';
import { HeaderComponent } from './containers/header/header.component';

import { RouterModule } from '@angular/router';
import { ExpansionComponent } from './components/expansion/expansion.component';
import { ChipComponent } from './components/chip/chip.component';
import { CounterComponent } from './components/counter/counter.component';
import { SelectComponent } from './components/select/select.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelComponent } from './components/label/label.component';
import { LabelViewComponent } from './containers/label-view/label-view.component';
import { TrimDirective } from './directives/trim.directive';

import { GoogleMapComponent } from './components/google-map/google-map.component';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { MultiChipComponent } from './components/multichip-autocomplete/multi-chip.component';
import { UpdatePropertyPipe } from './pipe/updateProperty.pipe';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { CardComponent } from './components/card/card.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { CovertArrayToNumberPipe } from './pipe/covert-array-to-number.pipe';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ChartComponent } from './components/chart/chart.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ImagePreviewComponent } from './containers/image-preview/image-preview.component';
import { MultivaluedInputComponent } from './multivalued-input/multivalued-input.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { NgxFileDropModule } from 'ngx-file-drop';
import { LottieModule } from 'ngx-lottie';
import { CustomLazyAPIKeyLoader } from '../mapKeyInjectionFactory';
import { environment } from 'src/environments/environment';
import { PasteMultipleInputComponent } from './components/paste-multiple-input/paste-multiple-input.component';
AgmCoreModule['oldForRoot'] = AgmCoreModule.forRoot;
AgmCoreModule.forRoot = (lazyMapsAPILoaderConfig)  => {
    const output = AgmCoreModule['oldForRoot'](lazyMapsAPILoaderConfig);
    const providers = output.providers;
    for(let i in providers) {
      if(providers[i].provide === MapsAPILoader) {
        providers[i].useClass = CustomLazyAPIKeyLoader;
      }
    }
    output.providers = providers;
    return output;
}

@NgModule({
  imports: [
    CommonModule,
    AngularEditorModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GoogleMapsServicesApiKey,
      libraries: [ 'places' ]
    }),
    PerfectScrollbarModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NgxFileDropModule,
    LottieModule
  ],
  exports: [
    AppMaterialModule,
    CrudComponent,
    HeaderComponent,
    ListViewComponent,
    SearchComponent,
    LabelViewComponent,
    UpdatePropertyPipe,
    AutocompleteComponent,
    DialogComponent,
    ChipComponent,
    CounterComponent,
    PerfectScrollbarModule,
    CardComponent,
    AccordionComponent,
    CovertArrayToNumberPipe,
    NgxPermissionsModule,
    ChartComponent,
    GoogleMapComponent,
    ImagePreviewComponent,
    PasteMultipleInputComponent
  ],
  declarations: [
    InputComponent,
    ButtonComponent,
    CheckboxComponent,
    RadioComponent,
    DateComponent,
    ErrorComponent,
    TextareaComponent,
    CrudComponent,
    FileComponent,
    MediaComponent,
    AutocompleteComponent,
    ListViewComponent,
    ExpansionComponent,
    SearchComponent,
    HeaderComponent,
    ChipComponent,
    CounterComponent,
    SelectComponent,
    DialogComponent,
    LabelComponent,
    LabelViewComponent,
    TrimDirective,
    GoogleMapComponent,
    MultiChipComponent,
    UpdatePropertyPipe,
    SnackbarComponent,
    CardComponent,
    AccordionComponent,
    HtmlEditorComponent,
    CovertArrayToNumberPipe,
    ChartComponent,
    MbPassLedgerComponent,
    ImagePreviewComponent,
    MultivaluedInputComponent,
    PasteMultipleInputComponent
  ],
  entryComponents: [ DialogComponent, SnackbarComponent, MbPassLedgerComponent ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    UpdatePropertyPipe,
    CovertArrayToNumberPipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class SharedModule { }
