import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../services/data/data.service';
import { SharedCollectionService } from 'src/app/collection/services/collection.service';
import { AppWebRoutes } from 'src/app/core/constants/app-web-routes';
import { fileSizeGlobal } from '../../constants/shared-constant';
import { MediaExtension, MediaType } from 'src/app/core/constants/app-constant';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: [ './file.component.scss' ]
})
export class FileComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  _file;
  @Input()
  form: FormGroup;
  url = '';
  public collectionImageCheck = false;
  mediaType = MediaType;
  format: any;
  public csvErrorMessageCheck = false;

  constructor (
    public dataService: DataService,
    private sharedCollectionService: SharedCollectionService) { }

  ngOnInit () {
    if (this.dataService.imageUrl) {
      this.url = this.dataService.imageUrl;
    }
    if (this._file.defaultValue) {
      this.url = this._file.defaultValue;
    }
    this.getFileType(this.url);
  }

  ngOnChanges () {
    this.getFileType(this.url);
  }

  onFileChange (event) {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      const file = event.target.files[ 0 ];
      const apiMediaType = this.getFileType(file.name);
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (this._file.accept === '.csv') {
          this._file.placeholder = file.name;
          this.dataService.apiValueForForm = { file: file };
          if (this._file.isShowCsvMessage) {
            if (file.size > fileSizeGlobal.defaultSize) {
              this.form.controls[ this._file.key ].patchValue(null);
              this._file.placeholder = 'File Upload';
              this.dataService.apiValueForForm = {};
              this.csvErrorMessageCheck = true;
            } else {
              this.csvErrorMessageCheck = false;
            }
          }
        } else {
          this._file.defaultValue = reader.result as string;
          if (this._file.fileSize && Number((event.target.files[ 0 ].size).toFixed(0)) < this._file.fileSize) {
            this.collectionImageCheck = false;
            this.dataService.dataToSendForImageUpload = {
              fileName: file.name,
              file: (reader.result as string).split(',')[ 1 ]
            };
            if (this._file.immediateImageUpload) {
              this.collectionImageCheck = false;
              let customApiEndPoint = this._file.customApiEndPoint;
              if (this._file.appendQueryParam) {
                customApiEndPoint = this._file.customApiEndPoint + '?mediaType=' + apiMediaType;
              }
              this.sharedCollectionService
                .uploadFile(
                  this._file.customApiEndPoint ? customApiEndPoint : AppWebRoutes.BANNER.imageupload,
                  this.dataService.dataToSendForImageUpload
                )
                .subscribe(res => {
                  this.dataService.images[ this._file.key ] = res[ 'fileName' ];
                });
            }
          } else if (!this._file.fileSize && Number((event.target.files[ 0 ].size).toFixed(0)) < fileSizeGlobal.defaultSize) {
            this.collectionImageCheck = false;
            this.dataService.dataToSendForImageUpload = {
              fileName: file.name,
              file: (reader.result as string).split(',')[ 1 ]
            };
            if (this._file.immediateImageUpload) {
              this.collectionImageCheck = false;
              let customApiEndPoint = this._file.customApiEndPoint;
              if (this._file.appendQueryParam) {
                customApiEndPoint = this._file.customApiEndPoint + '?mediaType=' + apiMediaType;
              }
              this.sharedCollectionService
                .uploadFile(
                  this._file.customApiEndPoint ? customApiEndPoint : AppWebRoutes.BANNER.imageupload,
                  this.dataService.dataToSendForImageUpload
                )
                .subscribe(res => {
                  this.dataService.images[ this._file.key ] = res[ 'fileName' ];
                });
            }
          } else if (!this._file.fileSize && Number((event.target.files[ 0 ].size).toFixed(0)) < fileSizeGlobal.defaultSize) {
            this.collectionImageCheck = false;
            this.dataService.dataToSendForImageUpload = {
              fileName: file.name,
              file: (reader.result as string).split(',')[ 1 ]
            };
          } else {
            this.collectionImageCheck = true;
            this.dataService.imageUrl = null;
            this._file.defaultValue = null;
            this.form.controls[ this._file.key ].patchValue(null);
            this.form.controls[ this._file.key ].setValidators([ Validators.required ]);
          }
        }
      };
    }
  }

  removeFile () {
    this.dataService.imageUrl = null;
    this._file.defaultValue = null;
    this.dataService.images[ this._file.key ] = null
    this.form.controls[ this._file.key ].patchValue(null);
    if (this._file.key === 'listImage') {
      this.form.controls[ this._file.key ].setValidators(null);
    } else if (this._file.validations.required) {
      this.form.controls[ this._file.key ].setValidators([ Validators.required ]);
    }
    if (this.dataService.images[ this._file.key ]) {
      delete this.dataService.images[ this._file.key ];
    }
    this.form.controls[ this._file.key ].updateValueAndValidity();
  }

  getFileType (fileName) {
    if (!fileName) {
      return;
    }
    let extension = fileName.split('.').pop();
    let apiMediaType = '';
    switch (extension) {
      case MediaExtension.JSON:
        this.format = this.mediaType.LOTTIE;
        apiMediaType = 'lottie';
        break;
      default:
        this.format = this.mediaType.IMAGE;
        apiMediaType = 'image';
        break;
    }
    return apiMediaType;
  }

  ngOnDestroy () {
    this.collectionImageCheck = false;
    this.dataService.imageUrl = null;
    this._file.defaultValue = null;
    this._file.placeholder = 'File Upload';
  }
}
