<div class="" [formGroup]="form">
  <!-- <h5 class="upload-img-label">Upload Image</h5> -->
  <div class="form-group" *ngIf="!(_file.defaultValue || dataService.imageUrl)">
    <div class="image-uploader-box">
      <div class="text-center">
        <mat-icon class="material-icons-outlined add-photo-icon">add_photo_alternate</mat-icon>
        <label> {{ _file.placeholder }} </label>
        <div class="upload-button">
          <span class="file-label">browse file</span>
          <input size="30" type="file" [formControlName]="_file.key" (change)="onFileChange($event)" [accept]="
              _file.accept
                ? _file.accept
                : 'image/x-png,image/gif,image/jpeg,image/svg+xml'
            " />
        </div>
      </div>
    </div>
  </div>
  <div class="image-uploader-box" *ngIf="(_file.defaultValue || dataService.imageUrl)">
    <ng-container *ngIf="(_file.appendQueryParam && format && (_file.defaultValue || dataService.images[_file.key])); else elseBlock">
      <ng-container [ngSwitch]="format">
        <ng-container *ngSwitchCase="mediaType.LOTTIE">
          <ng-lottie height="318px" [options]="{'path': _file.defaultValue || (_file.fileBasePath + dataService.images[_file.key])}"></ng-lottie>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <img class="img-uploaded" [src]="_file.defaultValue || _file.fileBasePath + dataService.images[_file.key]" onError="this.src='../../../../assets/img/no_image.jpg';" alt="..." />
        </ng-container>
        <button *ngIf="!_file.disabled" type="button" class="close-button" mat-icon-button (click)="removeFile()">
          <mat-icon class="material-icons-outlined">close</mat-icon>
        </button>
      </ng-container>
    </ng-container>
    <ng-template #elseBlock>
      <img class="img-uploaded" [src]="_file.defaultValue || dataService.imageUrl" onError="this.src='../../../../assets/img/no_image.jpg';" alt="..." />
      <button *ngIf="!_file.disabled" type="button" class="close-button" mat-icon-button (click)="removeFile()">
        <mat-icon class="material-icons-outlined">close</mat-icon>
      </button>
    </ng-template>
  </div>
</div>

<mat-error *ngIf="collectionImageCheck && _file.fileSize;else forSpecificItems">
  size should be less than 20kb</mat-error>
<ng-template #forSpecificItems>
  <mat-error *ngIf="collectionImageCheck">
    size should be less than 5mb for Image/gif/video</mat-error>
</ng-template>
<mat-error *ngIf="csvErrorMessageCheck">*File should be less than 5MB</mat-error>
<small *ngIf="_file.hintLabel" class="hint">{{ _file.hintLabel }}</small>
<app-error [form]="form" [_error]="_file"></app-error>
