import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  constructor (
    private httpService: HttpService,
  ) { }

  getDataById (url, userId): Observable<object> {
    return this.httpService.get(url, userId, null, true);
  }
}
