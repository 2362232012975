import { LAZY_MAPS_API_CONFIG, LazyMapsAPILoader, ɵc as WindowRef, ɵd as DocumentRef} from '@agm/core';
import { Inject, Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { appConstants } from './core/constants/app-constant';
import { environment } from 'src/environments/environment';
@Injectable()
export class CustomLazyAPIKeyLoader extends LazyMapsAPILoader {
    retry: number = 3;
    constructor(@Inject(LAZY_MAPS_API_CONFIG) _config: any, w:WindowRef, d: DocumentRef) {
        super(_config, w, d, 'en-US');
    }
    load(): Promise<any> {
        const loader = super.load.bind(this);
        const newPromise = new Promise(async (res, rej) => {
            if(!appConstants['firebase_masterdata']) {
                await this.getValueFromFirebase(this.retry);
            }
            let key = this.getApiKey(); // GET OUR KEY FROM YourAPI.json API --
            this._config.apiKey = key;
            loader().then((v) => res(v));
        });
        return newPromise;
    }
    getValueFromFirebase (retry: number) {
        return new Promise((resolve)=>{
            const remoteConfig = firebase.remoteConfig();
            remoteConfig.settings = {
            fetchTimeoutMillis: 60000,
            minimumFetchIntervalMillis: 1
            };
            remoteConfig.fetchAndActivate()
            .then(() => {
                this.getValueFromRemoteConfig('firebase_masterdata', appConstants);
                resolve(true);
            })
            .catch((error) => {
                if (++retry <= 3) {
                    this.getValueFromFirebase(retry);
                } else {
                    resolve(true);
                }
            });
        });
    }
    // Initializing the remote config, getting data from remote config and setting in app constant provided by parameter.
    getValueFromRemoteConfig (key, keyToSaveData) {
        const remoteConfig = firebase.remoteConfig();
        keyToSaveData[ key ] = JSON.parse(remoteConfig.getValue(key).asString());
        if(keyToSaveData[ key ]) {
            localStorage.setItem(
            environment.localStorageKeys.FIREBASE_MASTERDATA,
            JSON.stringify(keyToSaveData[ key ])
            );
        }
    }
    getApiKey() {
        const masterData: string = localStorage.getItem(environment.localStorageKeys.FIREBASE_MASTERDATA)
        appConstants.firebase_masterdata = masterData ? JSON.parse(masterData): null;
        return appConstants.firebase_masterdata && appConstants.firebase_masterdata['googleApiKey'] ? appConstants.firebase_masterdata['googleApiKey']: environment.GoogleMapsServicesApiKey;
    }
}