import { MediaMatcher } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { DataService } from '../../../shared/services/data/data.service';
import { appConstants, globalFilter } from '../../constants/app-constant';
import { LoginService } from '../login/login.service';
import { MatDialog } from '@angular/material/dialog';
import { GlobalFilterComponent } from '../global-filter/global-filter.component';
import { HttpService } from 'src/app/shared/services/http/http.service';
import { AppWebRoutes } from '../../constants/app-web-routes';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { NgxPermissionsService } from 'ngx-permissions';
declare var require: any;

interface Params {
  id?: string;
  mode?: string;
}

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: [ './side-nav.component.scss' ]
})
export class SideNavComponent implements OnInit, OnDestroy, AfterViewInit {
  isCollapsed = false;
  loginClick = false;
  toggleForCity = false;
  toggleForHub = false;
  public config;
  globalFilterDialog;
  public globalFilter = globalFilter;
  sideNavData = { pageTitle: '' };
  selectedComponentParams: Params;
  navLinks: any = [];
  mobileQuery: MediaQueryList;
  public showLoader = false;
  pageTitle: string;
  showMenu = false;
  hoverScroll = false;
  menuLinks: object;
  intitalValue;
  checkDisabled = false;
  cityListing: any;
  hubListing;
  cityDetails = {};
  private _mobileQueryListener: () => void;
  public phase = require('../../constants/phase-constant.json');
  groupCheck = [];
  constructor (
    private titleService: Title,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    public dataService: DataService,
    private authService: AuthService,
    private router: Router,
    private _route: ActivatedRoute,
    private loginService: LoginService,
    public dialog: MatDialog,
    private httpService: HttpService,
    public permissionsService: NgxPermissionsService
  ) { }

  ngOnInit () {
    setTimeout(() => {
      this.groupCheck = appConstants.masterData.EXTERNAL_PII_MASKED_GROUP.filter(item => Number(item.value) === this.dataService.userProfile.groupId);
    })
    this.addListeners();
    this.authService.isLoggedIn.subscribe(data => {
      this.dataService.resetGlobalFilter();
      if (data) {
        appConstants.masterData = this.dataService.getLocalStorage(
          environment.localStorageKeys.MASTERDATA
        );
        this.dataService.removeWFAccountClosureA2BFreezeOption(appConstants.masterData.WALLET_FREEZE);
        appConstants.firebase_masterdata = this.dataService.getLocalStorage(
          environment.localStorageKeys.FIREBASE_MASTERDATA
        );
        this.commonMapper(environment.localStorageKeys.CATEGORIES, AppWebRoutes.CATEGORIES.categories);
        this.commonMapper(environment.localStorageKeys.SUBCATEGORIES, AppWebRoutes.SUBCATEGORIES.subcategories);
        this.commonMapper(environment.localStorageKeys.HUBS, AppWebRoutes.HUBS.all, true);
        this.commonMapper(environment.localStorageKeys.CITIES, AppWebRoutes.CITIES.all, true);
      }
    });
  }

  // Common method to maps common keys which are going to be used later in application.
  commonMapper (keyToBeSavedInLocalStorage, apiToBeCalled, shouldStoreInArrayAndObject = false) {
    let keyToStoreData = JSON.parse(localStorage.getItem(keyToBeSavedInLocalStorage)) || {};
    if (Object.keys(keyToStoreData).length === 0) {
      this.httpService.get(apiToBeCalled).subscribe((responseFromAPI: any) => {
        if (responseFromAPI) {
          if (shouldStoreInArrayAndObject) {
            keyToStoreData = {
              keyValuePairArray: [],
              keyValuePairObject: {}
            };
            keyToStoreData.keyValuePairArray = responseFromAPI;
            responseFromAPI.forEach(element => {
              keyToStoreData.keyValuePairObject[ element.id ] = element;
            });
            appConstants.masterData[ keyToBeSavedInLocalStorage ] = keyToStoreData;
          } else {
            responseFromAPI.forEach(element => {
              keyToStoreData[ element.id ] = element;
            });
            appConstants[ keyToBeSavedInLocalStorage ] = keyToStoreData;
          }
          localStorage.setItem(
            keyToBeSavedInLocalStorage,
            JSON.stringify(keyToStoreData)
          );
        }
      });
    } else if (shouldStoreInArrayAndObject) {
      appConstants.masterData[ keyToBeSavedInLocalStorage ] = keyToStoreData;
    } else {
      appConstants[ keyToBeSavedInLocalStorage ] = keyToStoreData;
    }
  }

  ngAfterViewInit () {
    this.checkDisabled = this.dataService.masterHubCatalogToggleDisabled;
  }

  extraName () {
    if (
      this.dataService &&
      this.dataService.userProfile &&
      this.dataService.userProfile.firstName
    ) {
      return `${this.dataService.userProfile.firstName.substr(
        0,
        1
      )}${this.dataService.userProfile.lastName.substr(0, 1)}`;
    } else {
      return '';
    }
  }

  initAppPages () {
    this.navLinks = [];
    let navHeaders = [
      'user',
      'location',
      'search',
      'marketing',
      'customer',
      'collection',
      'basket',
      'order',
      'ppd',
      'selfhelp',
      'cims',
      'procurement',
      'transaction',
      'mastercatalog',
      'hubcatalog',
      'common',
      'configuration',
      'wms',
      'ims',
      'bdtracker'
    ];
    if (this.phase.phase1) {
      navHeaders = appConstants.phase1;
    } else if (this.phase.phase2) {
      navHeaders = appConstants.phase2;
    } else {
      // this.navLinks.push({ path: 'home', label: 'Home', component: 'HomeComponent', name: 'home', icon: 'home' });
    }

    navHeaders.forEach(nav => {
      if (nav === 'hubcatalog') {
        appConstants[ 'menu' ][ nav ][ 'root' ][
          'submenu'
        ][ 3 ].path = `/catalog/hub/${this.dataService.globalFilter.apiValue.hubId}/product`;
      }
      const navEle = appConstants[ 'menu' ][ nav ].root;
      this.navLinks.push(navEle);
    });
  }

  addListeners () {
    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.initAppPages();
    this.dataService.getSideNavData().subscribe(data => {
      setTimeout(() => {
        this.pageTitle = data.title;
        if (
          !!(
            this.authService.getAuthentication() &&
            this.authService.getAuthentication().profile &&
            this.authService.getAuthentication().profile[ 'hubName' ]
          )
        ) {
          this.showMenu =
            [
              appConstants.pages.login.label,
              appConstants.pages.pageNotFound.label,
              appConstants.pages.forgotPassword.label,
              appConstants.pages.updatePassword.label
            ].indexOf(this.pageTitle) === -1;
        } else {
          this.showMenu = false;
        }
      });
    });
    this.dataService.getLoader().subscribe(data => {
      setTimeout(() => {
        this.showLoader = data;
      });
    });
  }
  navScrollIn (nav) {
    this.hoverScroll = true;
    let sideWrap = document.querySelector(".sidenav-wrap");
    if (sideWrap.classList.contains("collapseit")) {
      const element = document.querySelector(".scrollTarget");
      let x = element.scrollTop;
      document.getElementById("set-scroll-margin").style.marginTop = "-" + x + "px";
      const dynId = document.getElementById(nav.name);
      let sideHeight = document.querySelector(".mat-sidenav-container").clientHeight - 240,
        offTop = dynId.offsetTop;
      if (offTop >= sideHeight) {
        dynId.classList.add("topShow");
      }
    }
  }

  navScrollOut (nav) {
    this.hoverScroll = false;
    document.getElementById("set-scroll-margin").style.marginTop = "0px";
    const dynId = document.getElementById(nav.name);
    if (dynId.classList.contains("topShow")) {
      dynId.classList.remove("topShow");
    }
  }
  /**
   *
   * @method to get active class for active sublink, based on current url
   * @param subnav
   * @param nav
   *
   */
  getSublinkActiveClass (subnav, nav) {
    let bestMatch = '';
    const currentUrl = this._route[ '_routerState' ].snapshot.url;

    // get the best possible match for link matching with url
    nav.submenu.forEach(link => {
      if (currentUrl.indexOf(link.path) > -1) {
        bestMatch = link.path;
      }
    });

    // when url matches current subnav path
    return currentUrl.indexOf(subnav.path) > -1 && bestMatch === subnav.path
      ? 'active-sublink'
      : '';
  }

  /**
   *
   * @method to get router link based on navigation element selected
   * @param nav
   */
  getRouterLink (nav) {
    return nav.submenu && nav.submenu.length ? nav.name : nav.path;
  }

  /**
   *
   * @method to select sub menu
   * @param $event
   */
  selectSubmenu ($event, subNav) {
    this.titleService.setTitle('MilkBasket | ' + subNav.label);
    // tslint:disable-next-line:no-unused-expression
    $event && $event.stopPropagation();
  }

  /**
   *
   * @method to navigate to a specific route based on element clicked on side menu
   * @param item navigation object
   * @param index
   */

  selectRouter (item, index) {
    this.titleService.setTitle('MilkBasket | ' + item.label);
    this.loginClick = false;
    if (this.mobileQuery.matches) {
      this.isCollapsed = this.mobileQuery.matches;
    }
    if (!item.submenu) {
      this.router.navigate([ item.path ]);
    }
  }

  logout () {
    this.titleService.setTitle('MilkBasket-Administration');
    this.loginClick = false;
    this.loginService.logOut().subscribe((success: Response) => {
      this.authService.logOut();
    });
  }

  openGlobalFilter () {
    if (this.globalFilterDialog) {
      return;
    }
    this.globalFilterDialog = this.dialog.open(GlobalFilterComponent, {
      disableClose: true
    });
    this.globalFilterDialog.afterClosed().subscribe(whichOperation => {
      this.globalFilterDialog = null;
      if (whichOperation === 'cancel') {
        this.dataService.resetGlobalFilter();
        this.dialog.closeAll();
      } else {
        this.dataService.refreshList.next(true);
        const profileValue = this.authService.getAuthentication();
        profileValue.profile[
          'hubId'
        ] = this.dataService.globalFilter.apiValue.hubId;
        profileValue.profile[
          'cityId'
        ] = this.dataService.globalFilter.apiValue.cityId;
        profileValue.profile[
          'hubName'
        ] = this.dataService.globalFilter.uivalue.hubId;
        profileValue.profile[
          'cityName'
        ] = this.dataService.globalFilter.uivalue.cityId;
        this.authService.setAuthentication(profileValue);
        this.dialog.closeAll();
      }
    });
  }

  onValChange (event, value) {
    localStorage.setItem(environment.localStorageKeys.CATALOG, value);
    this.dataService.hubMasterCatalog = value;
    this.dataService.removeFromLocalStorage(
      environment.localStorageKeys.PAGESIZE
    );
    this.dataService.setDefaultPagination();
    this.dataService.resetSearchData();
    this.dataService.refreshList.next(true);
  }

  fetchListing () {
    this.globalFilter[ 0 ][ 'options' ] = [];
    this.httpService
      .get(AppWebRoutes.CITIES.cities, null, { hideLoader: true })
      .subscribe(success => {
        this.globalFilter[ 0 ][ 'options' ] = success as Object[];
      });
  }

  toggleButton (event, item) {
    event.stopPropagation();
    if (item.key === 'cityId') {
      if (!this.toggleForCity) {
        this.toggleForCity = !this.toggleForCity;
        this.toggleForHub = !this.toggleForCity;
      } else {
        this.toggleForCity = !this.toggleForCity;
      }
    } else if (item.key === 'hubId') {
      if (!this.toggleForHub) {
        this.toggleForHub = !this.toggleForHub;
        this.toggleForCity = !this.toggleForHub;
      } else {
        this.toggleForHub = !this.toggleForHub;
      }
    }
  }

  updateAndSet (event, id, name, key) {
    if (key === 'cityId') {
      if (event) {
        event.stopPropagation();
        this.toggleForHub = true;
        this.toggleForCity = false;
      }
      this.cityDetails[ 'cityId' ] = id;
      this.cityDetails[ 'cityName' ] = name;
      this.httpService
        .get(AppWebRoutes.HUBS.hubs, null, { hideLoader: true, cityId: id })
        .subscribe(success => {
          this.globalFilter[ 1 ][ 'options' ] = success as Object[];
        });
    } else {
      this.toggleForCity = false;
      this.dataService.globalFilter.apiValue[ 'cityId' ] = this.cityDetails[
        'cityId'
      ];
      this.dataService.globalFilter.uivalue[ 'cityId' ] = this.cityDetails[
        'cityName'
      ];
      this.dataService.globalFilter.apiValue[ 'hubId' ] = id;
      this.dataService.globalFilter.uivalue[ 'hubId' ] = name;
      this.dataService.refreshList.next(true);
      const profileValue = this.authService.getAuthentication();
      profileValue.profile[
        'hubId'
      ] = this.dataService.globalFilter.apiValue.hubId;
      profileValue.profile[
        'cityId'
      ] = this.dataService.globalFilter.apiValue.cityId;
      profileValue.profile[
        'hubName'
      ] = this.dataService.globalFilter.uivalue.hubId;
      profileValue.profile[
        'cityName'
      ] = this.dataService.globalFilter.uivalue.cityId;
      this.authService.setAuthentication(profileValue);
    }
  }

  removeDropdown () {
    if (this.toggleForCity || this.toggleForHub) {
      this.toggleForCity = false;
      this.toggleForHub = false;
      this.cityDetails = {};
    }
  }

  setClass (item) {
    if (item.key === 'cityId' && this.toggleForCity) {
      return 'open';
    } else if (item.key === 'hubId' && this.toggleForHub) {
      return 'open';
    }
  }

  ngOnDestroy (): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.cityDetails = {};
    this.loginClick = false;
  }
}
