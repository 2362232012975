export const environment = {
  production: false,
  qa: true,
  uat: false,
  base_url: 'https://dev.milkbasket.in/milkbasket_dev_current/',
  file_url: 'https://dev-file.milkbasket.in/',
  base_admin_url: 'https://dev-admin.milkbasket.in/index.html#/',
  firebase_dynamic_api_url:
    'https://firebasedynamiclinks.googleapis.com/v1/shortLinks',
  firebase_dynamic_api_key: 'AIzaSyAN8ntl0tveGM6cYSvFHctPw_i4Nia1XOw',
  dynamic_link_prefix: 'https://app.milkbasket.com/links/',
  product_details_dynamic_link:
    'https://app.milkbasket.com/listing/product-detail/',
  store_app_id: 'com.milkbasket.app',
  localStorageKeys: {
    USER: 'user',
    FILTERS: 'filters',
    GROBALFILTER: 'globalFilters',
    PAGESIZE: 'pageSize',
    OPTIONPARAMS: 'optionParams',
    PAGENAME: 'pageName',
    CATALOG: 'catalog',
    MASTERDATA: 'masterData',
    FIREBASE_MASTERDATA: 'firebase_masterdata',
    CATEGORIES: 'categories',
    SUBCATEGORIES: 'subcategories',
    HUBS: 'hubs',
    CITIES: 'cities',
    SOCIETIES: 'societies',
    SEGMENTS: 'segments',
  },
  FIREBASE: {
    apiKey: 'AIzaSyCrKhIjzvlBQ-fSmK2rqvm3kdwd17o_me8',
    authDomain: 'milkbasket-admin.firebaseapp.com',
    databaseURL: 'https://milkbasket-admin.firebaseio.com',
    projectId: 'milkbasket-admin',
    storageBucket: 'milkbasket-admin.appspot.com',
    messagingSenderId: '840373459652',
    appId: '1:840373459652:web:dc735b175d759d92a5c791',
    measurementId: 'G-XJ6ZWZE1KF',
  },
  GoogleMapsServicesApiKey: 'AIzaSyBR-FZrtxtZbTJl06uI0JyrSbV0tCgyigA',
  issuePath: 'https://dev-cims.milkbasket.in/index.html#/issueList/',
  issueRedirection: 'https://dev-cims.milkbasket.in/index.html#/issue/',
  freshdeskPath: 'https://milkbasket.freshdesk.com/contacts',
  intercomPath: 'https://app.intercom.io/a/apps/qudw4qc1/users',
  messageKey: 'additionalProp',
};
