<div class="search-component">
  <mat-accordion>
    <mat-expansion-panel [expanded]="step === true" (opened)="this.step = true">
      <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
        <mat-panel-title>
          <!-- <img src="../../../../assets/img/filter.png"><span>Filters</span> -->
          <mat-icon class="material-icons-outlined mt">filter_list</mat-icon>
          <span>Filters</span>
          <div class="chip-holder">
            <ng-container *ngFor="let search of _search">
              <app-chip
                [_chip]="search.chipOptions"
                [_search]="_search"
                [search]="search"
              ></app-chip>
            </ng-container>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row">
        <div *ngFor="let search of _search;" [formGroup]="form" class="col-md-4 col-sm-12" (keyup.enter)="SearchOnEnter()">
          <div [ngSwitch]="search.type">
            <div *ngSwitchCase="'autocomplete'">
              <app-autocomplete
                [form]="form"
                [_autocomplete]="valueSend(search)"
                [_search]="_search"
              ></app-autocomplete>
            </div>
            <div *ngSwitchCase="'select'">
              <app-select [form]="form" [_select]="search"></app-select>
            </div>
            <div *ngSwitchCase="'checkbox'">
              <app-checkbox [form]="form" [_checkbox]="search"></app-checkbox>
            </div>
            <div *ngSwitchCase="'input'">
              <app-input [form]="form" [_input]="search"></app-input>
            </div>
            <div *ngSwitchCase="'date'">
              <app-date [form]="form" [_date]="search"></app-date>
            </div>
            <div *ngSwitchCase="'multichip'">
              <app-multi-chip
                [form]="form"
                [_multichip]="search"
              ></app-multi-chip>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-toolbar d-flex w-100 justify-content-end">
        <button
          type="button"
          mat-raised-button
          color="primary"
          class="mat-elevation-z btn-apply"
          (click)="applyFormData()"
        >
          Apply
        </button>
        <button
          type="button"
          mat-raised-button
          color="warn"
          class="mat-elevation-z btn-clear"
          *ngIf="
            dataService.searchFilters.count &&
            dataService.searchFilters.count > 0
          "
          (click)="resetFormData()"
        >
          Clear all filters
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
