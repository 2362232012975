import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/services/http/http.service';
import { Observable } from 'rxjs';
import { DataService } from '../../shared/services/data/data.service';

@Injectable({
  providedIn: 'root'
})
export class SharedPPDService {
  constructor (
    private httpService: HttpService,
    private dataService: DataService
  ) { }

  getData (url): Observable<object> {
    return this.httpService.post(url, { filterRequired: true });
  }
  getDataWithNoGlobalFilter (url): Observable<object> {
    return this.httpService.post(url, { filterRequired: true, removeGlobalFilter: true });
  }
  addData (url, formValue): Observable<object> {
    return this.httpService.post(url, formValue);
  }
  getDataByIdParams (url, data): Observable<object> {
    return this.httpService.get(url, null, data, false);
  }
  getAllList (url): Observable<object> {
    return this.httpService.get(url);
  }
  getDataById (url, orderId): Observable<object> {
    return this.httpService.get(url, orderId, null, true);
  }
  editData (url, formValue, orderId): Observable<object> {
    return this.httpService.put(url, formValue, orderId, true);
  }

  patchData (url, formValue): Observable<object> {
    return this.httpService.patch(url, null, formValue);
  }

  patchVehicle (url, formValue): Observable<object> {
    return this.httpService.patch(url, formValue);
  }

  exportCsv (url): Observable<object> {
    return this.httpService.exportCsv(url);
  }
  editDataPartial (url, formValue): Observable<object> {
    return this.httpService.put(url, formValue);
  }
  getDataByIdWithResolve (url, optionsToUrl, optionsToHeader): Observable<object> {
    return this.httpService.get(url, optionsToUrl, optionsToHeader, true);
  }
  getDataByIdNoParams (url, optionsToUrl, optionsToHeader): Observable<object> {
    return this.httpService.get(url, optionsToUrl, optionsToHeader);
  }
  uploadData (url, formValue, dataToSend): Observable<object> {
    return this.httpService.post(url, formValue, dataToSend);
  }
  patchDataOnParams (url, formValue): Observable<object> {
    return this.httpService.patch(url, null, formValue);
  }

  downloadPdf (url, fileName, view = false) {
    this.httpService.downloadPdf(url).subscribe(
      response => {
        // download file
        this.downloadFile(response, `${fileName}.pdf`);
        this.dataService.hideLoader();
      },
      error => {
        this.dataService.hideLoader();
      }
    );
  }
  downloadFile (data: any, fileName) {
    const parsedResponse = data;
    const blob = new Blob([ parsedResponse ], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  deleteData (url, id): Observable<object> {
    return this.httpService.delete(url, id, true);
  }

  deletePicking (url, payload): Observable<object> {
    return this.httpService.delete(url, null, false, null, payload);
  }

  saveVendorIdToLocal (transportVendorId: string) {
    sessionStorage.setItem('transportVendorId', transportVendorId)
  }

  getVendorIdFromLocal () {
    return sessionStorage.getItem('transportVendorId');
  }

  removeVendorIdFromLocal () {
    sessionStorage.removeItem('transportVendorId');
  }

  savedailyReportToLocal (key: string, value: string) {
    sessionStorage.setItem(key, value)
  }

  getdailyReportFromLocal (key: string) {
    return sessionStorage.getItem(key);
  }

  removedailyReportFromLocal (key: string) {
    sessionStorage.removeItem(key);
  }
  
  getCsv(url): Observable<object> {
    return this.httpService.getCsv(url);
  }
}
