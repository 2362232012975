import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DataService } from '../../../../shared/services/data/data.service';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { mbPassLedgerContainer } from 'src/app/shared/constants/shared-constant';

@Component({
  selector: 'app-mb-pass-ledger',
  templateUrl: './mb-pass-ledger.component.html'
})
export class MbPassLedgerComponent implements OnInit, OnDestroy {
  mbExpiryPassList: MatTableDataSource<object> = new MatTableDataSource();
  form: FormGroup;
  listViewData;

  constructor (
    private dataService: DataService,
    public permissionsService: NgxPermissionsService,
    public router: Router,
    public dialogRef: MatDialogRef<MbPassLedgerComponent>,
    @Inject(MAT_DIALOG_DATA) public mbPassLedger
  ) {
    this.listViewData = {
      listData: this.mbExpiryPassList,
      tableHeaders: mbPassLedgerContainer.tableHeader,
      metaData: Object.assign({}, mbPassLedgerContainer.metaData)
    };
  }

  ngOnInit () {
    this.mbExpiryPassList.data = this.mbPassLedger;
  }

  ngOnDestroy () {
    this.dataService.destroyObservable();
  }
}
