<div class="catalog-dialog" *ngIf="showPop">
    <div class="close-button-holder">
        <button close-button mat-icon-button class="set-right" [mat-dialog-close]="true" type="button">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="bundle">
        <div class="mat-dialog-wrap" *ngIf="anfAudioList.length; else nodataFound">
            <h2 class="heading" mat-dialog-title>ANF Audio Listing</h2>
            <mat-dialog-content>
                <table border="1" cellpadding="4">
                    <tr>
                        <th>Date</th>
                        <th>Duration</th>
                        <th>Start Time</th>
                        <th>Recording Urls</th>
                    </tr>
                    <tr *ngFor="let item of anfAudioList">
                        <td>{{item.date}}</td>
                        <td>{{item.duration}} s</td>
                        <td>{{item.startTime | date:'mediumTime'}}</td>
                        <td> <a target="{{ item.recordingUrl ?'_blank':''}}" href="{{ item.recordingUrl ? item.recordingUrl : 'javascript:void(0)' }}" class="orange">
                                {{ item.status !== 'completed' ? 'NA': 'Available' }}
                            </a>
                        </td>
                    </tr>
                </table>
            </mat-dialog-content>
        </div>
        <ng-template #nodataFound>
            <h2>No Data Found</h2>
        </ng-template>
    </div>

</div>
