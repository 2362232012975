<div class="search-container">
  <div class="search-form-group" [ngClass]="{ 'search-form-group-focus': inputClassFocus }" *ngIf="!listViewData.metaData.hideSearch">
    <button (click)="clearSearchData(searchInput)" class="close-button" type="button" mat-icon-button>
      <mat-icon class="material-icons-outlined">close</mat-icon>
    </button>
    <mat-form-field appearance="outline" *ngIf="!listViewData.metaData.hidelocalSearch">
      <mat-icon class="material-icons-outlined" matPrefix>search</mat-icon>
      <input matInput #searchInput (focus)="inputClassFocus = true" (keyup)="applyFilter($event.target.value)" placeholder="Search in this page" />
    </mat-form-field>
  </div>
  <div class="text-right export-button-holder" *ngIf="listViewData?.showOnlyExport || listViewData.metaData.viewExport && (listViewData.total && listViewData.total > 0)">
    <button type="button" [hidden]="listViewData.metaData.viewExportPrivilege && !permissionsService.getPermission(listViewData.metaData.viewExportPrivilege)"
      (click)="exportCsv(listViewData.metaData.viewExport)" mat-stroked-button color="primary">
      <mat-icon class="material-icons-outlined">save_alt</mat-icon>
      Export
    </button>
    <ng-container *ngIf="listViewData.checkBundle">
      <button type="button" (click)="exportCsv(listViewData.metaData.viewExport,true)" mat-stroked-button color="primary">
        <mat-icon class="material-icons-outlined">save_alt</mat-icon>
        Export Bundle
      </button>
    </ng-container>
  </div>
</div>
<div class="total-price" *ngIf="listViewData.totalPrice">
  <strong>Total Price : {{ listViewData.totalPrice }}</strong>
</div>
<mat-card class="Listing mat-elevation-z" [ngClass]="{'has-no-data': listViewData.listData.filteredData.length === 0}">
  <mat-card-content>
    <div class="tableCntr table-view has-border">
      <mat-table class="" [dataSource]="listViewData.listData" matSort matSortDisableClear matSortDirection="asc">
        <ng-container *ngFor="let row of listViewData.tableHeaders; let i = index">
          <ng-container [matColumnDef]="row.header">
            <mat-header-cell *matHeaderCellDef [mat-sort-header]="row.key" [disabled]="!row.sortBy">
              {{ row.header | uppercase }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="{{row.whatToDo == 'subkey'? element[row.key]['cssClass']: ''}}">
              <div [ngSwitch]="row.whatToDo">
                <div [ngClass]="{'remarkList':row.isMultipleImage }" *ngSwitchCase="'image'">
                  <ng-container *ngIf="!(row.isMultipleImage) else showMultipleImages">
                    <img *ngIf="(element[row.key] && element[row.key].length) else defaultImg" class="image-width" [src]="fileUrl + row['componentPath'] + element[row.key]"
                      onError="this.src='../../../../assets/img/no_image.jpg';" alt="..." />
                    <ng-template #defaultImg>
                      <img class="image-width" src="../../../../assets/img/no_image.jpg" alt="..." />
                    </ng-template>
                  </ng-container>
                  <ng-template #showMultipleImages>
                    <ng-container *ngFor="let i of element[row.key]">
                      <img *ngIf="i else defaultImg" [src]="fileUrl + row['componentPath'] + i" onError="this.src='../../../../assets/img/no_image.jpg';" alt="..." />
                      <ng-template #defaultImg>
                        <img src="../../../../assets/img/no_image.jpg" alt="..." />
                      </ng-template>
                    </ng-container>
                  </ng-template>
                </div>
                <div *ngSwitchCase="'navigateToView'" [hidden]="row.privilege && !permissionsService.getPermission(row.privilege)">
                  <a [routerLink]="navigateToView(element, row)">
                    {{ row.subkey ? element[row.key][row.subkey] : element[row.key] }}
                  </a>
                  <small class="d-block" *ngIf="row['label']">{{ row['label'] ? element[row.label] : '' }}</small>
                </div>
                <div *ngSwitchCase="'navigate'" [hidden]="row.privilege && !permissionsService.getPermission(row.privilege)">
                  <a [class]="element[row.linkId] <= 0 ? 'no-pointer' : ''" [routerLink]="navigateToPage(element, row)" [innerHtml]="element | updateProperty: row"></a>
                </div>
                <div *ngSwitchCase="'navigateToNewTab'" [hidden]="row.privilege && !permissionsService.getPermission(row.privilege)">
                  <a [class]="element[row.linkId] <= 0 ? 'no-pointer' : ''" (click)="navigateToNewTab(element, row)" [innerHtml]="element | updateProperty: row"></a>
                </div>
                <div *ngSwitchCase="'externalURL'" [hidden]="row.privilege && !permissionsService.getPermission(row.privilege)">
                  <a [class]="element[row.linkId] <= 0 ? 'no-pointer' : ''" (click)="navigateToURL(element)" [innerHtml]="element | updateProperty: row"></a>
                </div>
                <div *ngSwitchCase="'clickableCell'" [hidden]="row.privilege && !permissionsService.getPermission(row.privilege)">
                  <a (click)="onCellClick(element, row.key)" [innerHtml]="element | updateProperty: row"></a>
                </div>
                <div *ngSwitchCase="'addlabel'">
                  <span>{{ element[row.key] }}</span>
                  <small class="d-block" *ngIf="row['label']">{{ row['label'] ? element[row.label] : '' }}</small>
                </div>
                <div *ngSwitchCase="'subKeyArray'">
                  <span *ngIf="row.pipe">
                    <span class="innerTable" *ngFor="let ele of element[row.key]" [ngClass]="{ newLine: row.pipeConfig.requiresNewLine }" [innerHtml]="ele | updateProperty: row ">
                    </span>
                  </span>
                  <span *ngIf="!row.pipe">
                    <span class="innerTable" *ngFor="let ele of element[row.key]" [innerHtml]="ele[row.subkey]"> </span>
                  </span>
                </div>
                <div *ngSwitchCase="'subkey'">
                  <span *ngIf="row.pipe">
                    {{ element | updateProperty: row }}
                  </span>
                  <span *ngIf="!row.pipe">{{ element[row.key][row.subkey] }}</span>
                </div>
                <div *ngSwitchCase="'button'" [hidden]="row.privilege && !permissionsService.getPermission(row.privilege)">
                  <button type="button" mat-stroked-button color="primary" *ngIf="element[row.key] === 'Mark as Done'" (click)="markAsDone(element)" class="button-md">
                    Mark as Done
                  </button>
                  <span *ngIf="!(element[row.key] === 'Mark as Done')">{{ element | updateProperty: row }}</span>
                </div>
                <div *ngSwitchCase="'icon'" [hidden]="row.privilege && !permissionsService.getPermission(row.privilege)">
                  <button type="button" mat-stroked-button *ngIf="!element[row.key]" class="action-item" (click)="deleteData(element.id, element)">
                    <mat-icon class="material-icons-outlined">delete</mat-icon>
                  </button>
                  <span *ngIf="element[row.key]">{{ element | updateProperty: row }}</span>
                </div>
                <div *ngSwitchCase="'iconUpdate'" [hidden]="row.privilege && !permissionsService.getPermission(row.privilege)">
                  <button type="button" mat-stroked-button *ngIf="element[row.key] === 1" class="action-item" (click)="deleteData(element.id, element)">
                    <mat-icon class="material-icons-outlined">delete</mat-icon>
                  </button>
                  <span *ngIf="element[row.key] !== 1">{{ element | updateProperty: row }}</span>
                </div>
                <div *ngSwitchCase="'manage'" [hidden]="row.privilege && !permissionsService.getPermission(row.privilege)">
                  <a [routerLink]="navigateToPage(element, row)">{{ row.key }}</a>
                </div>
                <div *ngSwitchCase="'formDetails'" [hidden]="row.privilege && !permissionsService.getPermission(row.privilege)">
                  <a (click)="formDetails(element, row)">{{ row.key }}</a>
                </div>
                <div *ngSwitchCase="'openModal'" [hidden]="row.privilege && !permissionsService.getPermission(row.privilege)">
                  <a *ngIf="element[row.key] !== 'NA'" (click)="openContactDetailModal(element, row)"> <span [ngClass]="setClasses(element | updateProperty: row)"
                      [innerHtml]="element | updateProperty: row"></span> </a>
                  <span *ngIf="element[row.key] === 'NA'" [ngClass]="setClasses(element | updateProperty: row)" [innerHtml]="element | updateProperty: row"></span>
                </div>
                <div *ngSwitchCase="'counter'">
                  <app-counter *ngIf="listViewData.actions.length" [element]="element" [row]="row" [disabled]="element.hubAcceptQty != null ? 1 : 0">
                  </app-counter>
                  <label *ngIf="!listViewData.actions.length">{{element.hubAcceptQty ? element.hubAcceptQty : 0}}/{{element.deAcceptQty}}</label>
                </div>
                <div *ngSwitchDefault>
                  <span *ngIf="!row.pipeConfig?.customPipe" [ngClass]="setClasses(element | updateProperty: row)" [innerHtml]="element | updateProperty: row"></span>
                  <span *ngIf="row.pipeConfig?.customPipe" [ngClass]="setClasses(element | updateProperty: row)">
                    {{ row.pipe.transform(element[row.key], row.pipeConfig.pipeArg) }}</span>
                </div>
                <div *ngSwitchCase="'addClass'">
                  <span [ngClass]="setClasses(element[row.key], element)">{{ element[row.key] }}</span>
                </div>
              </div>
            </mat-cell>
          </ng-container>
        </ng-container>
        <ng-container matColumnDef="Actions" *ngIf="listViewData.actions">
          <mat-header-cell *matHeaderCellDef> ACTIONS</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="actionBtns">
              <ng-container *ngFor="let action of listViewData.actions">
                <button mat-button
                  *ngIf="(!(action.getIcon && action.getIcon(element) === '')) &&  action.icon != 'submit' && action.label !='Edit' && action.label !='EditProduct' && action.label !='settingParams'"
                  [hidden]="action.privilege && !permissionsService.getPermission(action.privilege)" class="action-item" (click)="whichAction(element,
                  action)">
                  <mat-icon [matTooltip]="action.getLabel  ? action.getLabel(element) : action.label" class="material-icons-outlined">
                    {{ action.getIcon ? action.getIcon(element) : action.icon }}
                  </mat-icon>
                </button>
                <button *ngIf="action.icon == 'submit'" [disabled]="element.hubAcceptQty" class="btn-submit" (click)="submitData(element, action)"
                  [hidden]="action.privilege && !permissionsService.getPermission(action.privilege)" mat-flat-button color="primary">{{action.label}}</button>

                <a mat-button *ngIf="(!(action.getIcon && action.getIcon(element) === '')) && action.label =='Edit' && action.label !='settingParams' "
                  [hidden]="action.privilege && !permissionsService.getPermission(action.privilege)" class="action-item editColorSet" [routerLink]="EditActionSet(element,
                  action)">

                  <mat-icon [matTooltip]="element.active && action.getIcon  ? 'Deactivate' : action.label" class="material-icons-outlined">
                    {{ action.getIcon ? action.getIcon(element) : action.icon }}
                  </mat-icon>
                </a>
                <a mat-button *ngIf="(!(action.getIcon && action.getIcon(element) === '')) && action.label =='EditProduct' && action.label !='Edit' && action.label !='settingParams'"
                  [hidden]="action.privilege && !permissionsService.getPermission(action.privilege)" class="action-item editColorSet" [routerLink]="EditActionSet(element, action)">

                  <mat-icon [matTooltip]="element.active && action.getIcon  ? 'Deactivate' : action.label" class="material-icons-outlined">
                    {{ action.getIcon ? action.getIcon(element) : action.icon }}
                  </mat-icon>
                </a>
                <a mat-button *ngIf="(!(action.getIcon && action.getIcon(element) === '')) && action.label !='EditProduct' && action.label !='Edit' && action.label =='settingParams' "
                  [hidden]="action.privilege && !permissionsService.getPermission(action.privilege)" class="action-item editColorSet" [routerLink]="EditActionSet(element, action)">

                  <mat-icon [matTooltip]="element.active && action.getIcon  ? 'Deactivate' : action.label" class="material-icons-outlined">
                    {{ action.getIcon ? action.getIcon(element) : action.icon }}
                  </mat-icon>
                </a>
              </ng-container>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Actions" *ngIf="listViewData.metaData.checkbox">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox color="primary" class="check-box" matTooltip="SelectAll" (change)="selectAll($event)">SELECT ALL
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div>
              <mat-checkbox color="primary" class="check-box" [(ngModel)]="element.checkboxValue" (change)="checkboxValue(element, $event)"></mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableCols"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableCols;let indexRow = index"></mat-row>
      </mat-table>
      <div class="no-data-found" *ngIf="listViewData.listData.filteredData.length === 0">
        <strong>NO DATA FOUND</strong>
      </div>
    </div>

    <!-- Pagination for table -->
    <mat-paginator *ngIf="!listViewData.metaData.hidePagination" #paginator [length]="listViewData.total" [pageSize]="dataService.paginationData.pageSize"
      [pageSizeOptions]="dataService.pageSizeOptions" [pageIndex]="dataService.listPageIndex" (page)="updatePagination($event)">
    </mat-paginator>
  </mat-card-content>
</mat-card>
