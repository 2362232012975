import { Injectable } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { formServiceActions } from '../../constants/shared-constant';
import { DataService } from '../data/data.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  public form: FormGroup;
  constructor (private dataService: DataService, private fb: FormBuilder) { }

  createFormGroup (crudData): FormGroup {
    const form = {};
    const storageFilters = this.dataService.getLocalStorage(
      environment.localStorageKeys.FILTERS
    );
    crudData.forEach((element, index) => {
      if (element && element.key) {
        form[ element.key ] = new FormControl(
          {
            value: element.defaultValue,
            disabled: !!element.disabled
          },
          this.validator(element)
        );
      }
      // following code will add pre filled values for date in search filters only
      if (element.module === formServiceActions.search) {
        if (
          element.defaultValue &&
          element.type === formServiceActions.date &&
          (!storageFilters ||
            (storageFilters && !storageFilters.isFilterPreFilled))
        ) {
          if (!storageFilters) {
            this.dataService.searchFilters.isFilterPreFilled = true;
          }
          if (element.defaultValue instanceof Date) {
            this.dataService.setSearchData(
              element,
              this.dataService.dateFormat(element.defaultValue),
              element.key,
              this.dataService.dateFormat(element.defaultValue),
              true
            );
            element.chipOptions = {
              key: element.key,
              value: this.dataService.dateFormat(element.defaultValue),
              uiKey: element.uiKey,
              apiKey: element.apiKey
            };
          } else {
            const startDate = this.dataService.dateFormat(
              element.defaultValue[ 0 ]
            );
            const endDate = this.dataService.dateFormat(
              element.defaultValue[ 1 ]
            );
            this.dataService.setSearchData(
              element,
              startDate,
              element.key,
              startDate,
              true
            );
            this.dataService.setSearchData(
              element,
              endDate,
              element.dateRangeKey,
              endDate,
              true
            );
            element.chipOptions = {
              key: element.key,
              value: startDate + ' ~ ' + endDate,
              uiKey: element.uiKey,
              apiKey: element.apiKey,
              target: element.dateRangeKey
            };
          }
          form[ element.key ].reset('');
        } else if (
          typeof element.defaultValue === 'number' ||
          typeof element.defaultValue === 'string'
        ) {
          if (element.type === 'select') {
            let chipValue: string;
            for (let i = 0; i < element.options.length; i++) {
              if (element.options[ i ].value === element.defaultValue) {
                chipValue = element.options[ i ].placeholder;
                break;
              }
            }
            element.chipOptions = {
              key: element.key,
              value: chipValue,
              uiKey: element.uiKey,
              apiKey: element.apiKey
            };
          } else {
            element.chipOptions = {
              key: element.key,
              value: element.defaultValue,
              uiKey: element.uiKey,
              apiKey: element.apiKey
            };
          }

          this.dataService.setSearchData(
            element,
            element.defaultValue,
            element.key,
            element.defaultValue,
            true
          );
        }
        this.dataService.setLocalStorage(
          environment.localStorageKeys.FILTERS,
          this.dataService.searchFilters
        );
      }
    });
    return new FormGroup(form);
  }

  addControl (control) {
    return this.fb.control(control.defaultValue, this.validator(control));
  }

  validator (crudData) {
    const validations = [];
    if (crudData && crudData.validations && crudData.validations.required) {
      validations.push(Validators.required);
    }
    if (crudData && crudData.validations && crudData.validations.minlength) {
      validations.push(Validators.minLength(crudData.validations.minlength));
    }
    if (crudData && crudData.validations && crudData.validations.maxlength) {
      validations.push(Validators.maxLength(crudData.validations.maxlength));
    }
    if (crudData && crudData.validations && crudData.validations.pattern) {
      validations.push(Validators.pattern(crudData.validations.pattern));
    }
    if (crudData && crudData.validations && crudData.validations.min) {
      validations.push(Validators.min(crudData.validations.min));
    }
    if (crudData && crudData.validations && crudData.validations.max) {
      validations.push(Validators.max(crudData.validations.max));
    }
    return validations;
  }

  patchValue (viewData, viewParams, response) {
    for (let i = 0; i < viewParams.length; i++) {
      if (viewParams[ i ].key in response && !viewParams[ i ].resolve) {
        const labelValue = {
          placeHolder: viewParams[ i ].placeholder,
          value: response[ viewParams[ i ].key ]
        };
        if (viewParams[ i ].navigateTo) {
          labelValue[ 'navigateTo' ] = viewParams[ i ].navigateTo;
          labelValue[ 'linkId' ] = response[ viewParams[ i ].linkId ];
        }
        viewData.push(labelValue);
      } else if (Array.isArray(response[ viewParams[ i ].resolve ])) {
        const locations = [];
        if (response[ viewParams[ i ].resolve ].length > 0) {
          response[ viewParams[ i ].resolve ].forEach(obj => {
            locations.push(obj[ viewParams[ i ].key ]);
          });
        }
        viewData.push({
          placeHolder: viewParams[ i ].placeholder,
          value: locations
        });
      } else if (viewParams[ i ].resolve) {
        const labelValue = {
          placeHolder: viewParams[ i ].placeholder,
          value: response[ viewParams[ i ].resolve ],
          key: viewParams[ i ].key
        };
        viewData.push(labelValue);
      }
    }
  }

  patchValueEdit (CrudConstants, Response, form: FormGroup) {
    const crudEdit = {};
    for (let i = 0; i < CrudConstants.length; i++) {
      if (CrudConstants[ i ].key in Response) {
        crudEdit[ CrudConstants[ i ].key ] = Response[ CrudConstants[ i ].key ];
      }
    }
    form.patchValue(crudEdit);
  }

}
