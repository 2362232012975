import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataService } from '../../services/data/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ListViewService } from './list-view.service';
import { environment } from '../../../../environments/environment';
import { FormGroup } from '@angular/forms';
import { AppWebRoutes } from 'src/app/core/constants/app-web-routes';
import { merge } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { FormService } from '../../services/form/form.service';
import { SharedCustomerService } from 'src/app/customer/services/shared-customer.service';
import { MatDialog } from '@angular/material/dialog';
import { anfDialogComponent } from 'src/app/ppd/containers/anf/anf-dialog/anf-dialog.component';
import { WindowRef } from 'src/app/core/constants/windowRef';
import { appConstants, CCAction } from 'src/app/core/constants/app-constant';
import { ContactDetailsComponent } from 'src/app/bd-tracker/containers/rwa-directory/contact-details/contact-details.component';
import { SharedPPDService } from 'src/app/ppd/services/shared-ppd.service';
import { CLUSTER_TYPE } from 'src/app/ppd/constants/ppd-constant';
import { ProductReturnImagesComponent } from 'src/app/ppd/containers/productReturn/product-return-images/product-return-images.component';
@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: [ './list-view.component.scss' ]
})
export class ListViewComponent implements OnInit, OnDestroy {
  @Input()
  listViewData: any;
  @Input() form: FormGroup;
  @Output() getPaginatedData: EventEmitter<any> = new EventEmitter<boolean>();
  @Output() cellClickAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() dialogUpdate: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Output() editAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() localDeleteAction: EventEmitter<any> = new EventEmitter<any>();
  tableCols: Array<string> = [];
  arrayOfPrivileges = [];
  fileUrl = environment.file_url;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('searchInput') searchInput!: ElementRef;
  inputClassFocus = false;
  optionParams: any;
  constructor (
    public dataService: DataService,
    private router: Router,
    private _route: ActivatedRoute,
    private listViewService: ListViewService,
    public permissionsService: NgxPermissionsService,
    public formService: FormService,
    public sharedCustomerService: SharedCustomerService,
    private activatedRoute: ActivatedRoute,
    private sharedPPDService: SharedPPDService,
    public dialog: MatDialog,
    private winRef: WindowRef
  ) {
    this.dataService.isListView = true;
    this.dataService.setDefaultPagination();
    this.getPaginatedData.emit();
  }

  ngOnInit () {
    this.optionParams = null;
    if (this.listViewData[ 'hideListView' ]) {
      this.dataService.isListView = false;
    }
    this.dataService.refreshList.subscribe(data => {
      if (data) {
        this.tableCols = this.listViewData.tableHeaders.map(hdr => hdr.header);
        if (
          this.listViewData.metaData.checkbox ||
          (this.listViewData.actions && this.listViewData.actions.length > 0)
        ) {
          this.tableCols.push('Actions');
        }
      }
    });
    const reduceFilterObject = (data): string => {
      return Object.keys(data).reduce(function (currentTerm, key) {
        if (data[ key ] && typeof data[ key ] === 'object') {
          return currentTerm + reduceFilterObject(data[ key ]) + '◬';
        }
        return currentTerm + data[ key ] + '◬';
      }, '');
    };
    this.listViewData.listData.filterPredicate = (data, filter) => {
      const dataStr = reduceFilterObject(data).toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
    this.listViewData.listData.sort = this.sort;
    this.listViewData.listData.sortingDataAccessor = (data, sortHeaderId) => {
      if (typeof data[ sortHeaderId ] === 'string') {
        data[ sortHeaderId ].toLowerCase();
      }
    };
    merge(this.sort.sortChange).subscribe(data => {
      this.dataService.paginationData.sort = {
        [ data[ 'active' ] ]: data[ 'direction' ] === 'desc' ? 'DESC' : 'ASC'
      };
      this.dataService.refreshList.next(true);
    });
    this.arrayOfPrivileges = this.listViewData.groupPrivileges;
    this.tableCols = [];
    this.tableCols = this.listViewData.tableHeaders.map(hdr => hdr.header);
    if (
      this.listViewData.metaData.checkbox ||
      (this.listViewData.actions && this.listViewData.actions.length > 0)
    ) {
      this.tableCols.push('Actions');
    }
    if (this.dataService.paginationData.pageNo > 1) {
      this.dataService.listPageIndex =
        this.dataService.paginationData.pageNo - 1;
    }
  }

  updatePagination (evt) {
    if (evt.length >= evt.pageSize) {
      this.dataService.paginationData.pageSize = evt.pageSize;
      this.dataService.setLocalStorage(
        environment.localStorageKeys.PAGESIZE,
        evt.pageSize
      );
      this.dataService.paginationData.pageNo = evt.pageIndex + 1;
      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: { pageIndex: evt.pageIndex + 1 },
          queryParamsHandling: 'merge'
        });
      this.getPaginatedData.emit();
    }
  }

  setClasses (classToAdd, element) {
    const classes = {
      Cancelled: 'cancelled',
      Successful: 'successful',
      Pending: 'pending',
      procure: 'procure',
      decline: 'decline',
      cancelled: 'cancelled',
      suggest_product: 'cancelled',
      cancel: 'cancel'
    };
    if (element?.ccActionId) {
      switch (element.ccActionId) {
        case CCAction.Approved:
          return 'successful';
        case CCAction.Declined:
          return 'decline';
        default:
          return '';
      }
    }
    return classes[ classToAdd ];
  }

  reloadFilteredData () {
    this.dataService.listPageIndex = 0;
    this.dataService.paginationData.pageNo = 1;
    this.getPaginatedData.emit();
    if (
      this.listViewData.mapping === 'clusters' ||
      this.listViewData.mapping === 'feature-meta' ||
      this.listViewData.mapping === 'cities' ||
      this.listViewData.mapping === 'teams' ||
      this.listViewData.mapping === 'vendorProcure' ||
      this.listViewData.mapping === 'delivery-type' ||
      this.listViewData.mapping === 'hub-meta' ||
      this.listViewData.mapping === 'city-meta' ||
      this.listViewData.mapping === 'delivery-type' ||
      this.listViewData.mapping === 'properties' ||
      this.listViewData.mapping === 'city-hub' ||
      this.listViewData.mapping === 'cluster-deactivaction'
    ) {
      this.dataService.refreshList.next(true);
    }
  }

  whichAction (element, action) {
    if (action.operation && action.operation[ 'id' ]) {
      element.id = element[ action.operation[ 'id' ] ];
    }
    if (action.operation && action.operation[ 'name' ]) {
      element.name = element[ action.operation[ 'name' ] ];
    }
    if (element.name) {
      this.dataService.pageName = element.name;
      this.dataService.setLocalStorage(
        environment.localStorageKeys.PAGENAME,
        element.name
      );
    }
    if (element.reason) {
      this.dataService.pageName = element.reason;
      this.dataService.setLocalStorage(
        environment.localStorageKeys.PAGENAME,
        element.reason
      );
    }

    if (action.label === 'DeleteBasket') {
      this.listViewData.metaData[ 'dialogBox' ][ 'crud' ][ 0 ][ 'options' ] = [];
      for (let i = 1; i <= element.productQuantity; i++) {
        this.listViewData.metaData[ 'dialogBox' ][ 'crud' ][ 0 ][ 'options' ].push({ value: i, placeholder: i });
      }
      this.listViewData.metaData[ 'dialogBox' ] = {
        title: 'Delete Basket',
        why: 'formUpdate',
        crud: this.listViewData.metaData[ 'dialogBox' ][ 'crud' ],
        form: this.formService.createFormGroup(this.listViewData.metaData[ 'dialogBox' ][ 'crud' ])
      };
      this.listViewData.metaData.dialogBox.form.controls[ 'productQuantity' ].patchValue(element.productQuantity);
      const dialogRef = this.dataService.openDialog(this.listViewData.metaData.dialogBox);
      dialogRef.subscribe(result => {
        const apiUrl = element.productQuantity === result.value.productQuantity ? `${this.listViewData.metaData.delete.url}/
        ${element.productId}/date` : `${this.listViewData.metaData.delete.url}/${element.productId}/quantity/${result.value.productQuantity}`;
        this.optionParams = {
          sendMessage: true,
          productQuantity: result.value.productQuantity
        };
        const dataToSend = {
          date: this.listViewData.date,
          bypassDayDiffCheck: true,
          bypassCutoffTimeCheck: true,
          bypassMaxQuantityCheck: true
        };
        return element.productQuantity === result.value.productQuantity ? this.listViewService.deleteData(apiUrl, this.listViewData.date, this.optionParams).subscribe(res => {
          this.dataService.setDefaultPagination();
          this.reloadFilteredData();
        }) : this.listViewService.postData(apiUrl, dataToSend).subscribe(res => {
          this.reloadFilteredData();
        });
      });
    }
    if (action.label === 'Edit') {
      this.dataService.resetPagination = true;
      if (action.operation === 'acceptOrder') {
        const dialogRef = this.dataService.openDialog(this.listViewData.metaData.dialogBox);
        dialogRef.subscribe(result => {
          const apiUrl = `${AppWebRoutes.SUBCATEGORIES.subcategories}/${element.subCategoryId}/hubs/${this.dataService.globalFilter.apiValue.hubId}/update-variants-view`;
          this.listViewService.patchData(apiUrl, {
            variantsView: result.value.variantsView
          }).subscribe(res => { });
        });
      }
      if (action.operation && action.operation[ 'dependentOn' ]) {
        this.router.navigate([
          this.listViewData.metaData.editRoute,
          element[ action.operation[ 'dependentOn' ] ],
          element.id
        ]);
      } else {
        if (element[ 'isBundle' ] === 1) {
          this.router.navigate([
            this.listViewData.metaData.viewRoute,
            element.id
          ]);
        } else {
          this.router.navigate([
            this.listViewData.metaData.editRoute,
            element.id || element.vendorId
          ]);
        }
      }
    } else if (action.label === 'EditCategory') {
      this.router.navigate([
          `/${this.listViewData.metaData.editRoute}/${element.id ||
            element.vendorId}/${element.hubId || this.dataService.globalFilter.apiValue.hubId}`
        ]);
    } else if (action.label === 'EditSubCategory') {
      this.router.navigate([
          `/${this.listViewData.metaData.editRoute}/${element.id ||
            element.vendorId}/${element.hubId || this.dataService.globalFilter.apiValue.hubId}`
        ]);
    } else if (action.label === 'EditProduct') {
      if (element.isBundle) {
        this.router.navigate([
          `${this.listViewData.metaData.editRoute}/${this.dataService.globalFilter.apiValue.hubId}/bundle/${element.id}/edit`
        ]);
      } else {
        this.router.navigate([
          `${this.listViewData.metaData.editRoute}/${this.dataService.globalFilter.apiValue.hubId}/product/${element.id}/edit`
        ]);
      }
    } else if (action.componentPath === 'hubRevenue') {
      if (action.label === 'editLineItems') {
        this.router.navigate([ `/${this.listViewData.metaData.editRoute}-lineItems/${element.lineItem}/${element.categoryId}/${element.subcategoryId}` ]);
      } else {
        this.router.navigate([ `/${this.listViewData.metaData.editRoute}/${element.lineItem}` ]);
      }
    } else if (action.whatToDo === 'manage') {
      this.navigateToPage(element, action);
    } else if (action.label === 'RecurringOrder') {
      this.router.navigate([
        `customer/recurring/edit/${element.customerId}/${element.recOrderId}`
      ]);
    } else if (action.label === 'Delete') {
      const dialogRef = this.dataService.openDialog(
        this.listViewData.metaData.delete
      );
      dialogRef.subscribe(result => {
        if (result) {
          this.deleteData(element.id, element);
        }
      });
    } else if (action.label === 'DeleteAllocation') {
      const dialogRef = this.dataService.openDialog(
        this.listViewData.metaData.delete
      );
      dialogRef.subscribe(result => {
        if (result) {
          this.deleteData(element.allocationId, element);
        }
      });
    }
    else if (action.label === 'AllocateBundleImages') {
      const dialogRef = this.dataService.openDialog(
        this.listViewData.metaData.add
      );
      dialogRef.subscribe(result => {
        if (result) {
          this.listViewService.postData(this.listViewData.metaData.add.url, {
            productId: this.listViewData.metaData.id,
            sort: 1,
            imageSrc: element.imageSrc
          }).subscribe(() => {
            this.dataService.setDefaultPagination();
            this.reloadFilteredData();
          });
        }
      });
    } else if (action.label === 'Cancel Inventory Movement') {
      // only requested and inTransit movement can be cancelled
      if (element.status === 0 || element.status === 1) {
        const dialogRef = this.dataService.openDialog(
          this.listViewData.metaData.confirmation
        );
        dialogRef.subscribe(result => {
          if (result) {
            const requestBody = {
              hubId: this.dataService.globalFilter.apiValue.hubId,
              fromLocationId: element.fromLocationId,
              toLocationId: element.toLocationId,
              looseId: element.looseId,
              date: element.date
            };

            const url = action.operation.url;
            this.listViewService.postData(url,
              requestBody).subscribe(() => {
                this.dataService.setDefaultPagination();
                this.reloadFilteredData();
              });
          }
        });
      }
    } else if (action.label === 'Input Delay Reason') {
      const dialogRef = this.dataService.openDialog(
        this.listViewData.metaData.sortOrder
      );
      const requestBody = {
        hubId: this.dataService.globalFilter.apiValue.hubId,
        clusterId: element.clusterId,
        date: (element.date) ? element.date : this.dataService.dateFormat(new Date()),
        milestoneName: element.milestone,
        actualTime: element.actualTime,
        targetTime: element.targetTime
      };
      dialogRef.subscribe(result => {
        if (result) {
          requestBody[ 'delayReason' ] = result;
          this.listViewService
            .postData(AppWebRoutes.PPD.createMilestoneDelay, requestBody)
            .subscribe(res => {
              if (res) {
                this.dataService.refreshList.next(true);
              }
            });
        }
      });
    } else if (action.label === 'AllocateBundlePrimaryImage') {
      const dialogRef = this.dataService.openDialog(
        this.listViewData.metaData.add
      );
      dialogRef.subscribe(result => {
        if (result) {
          const url = `${this.listViewData.metaData.add.url}/${this.listViewData.metaData.id}/image`;
          this.listViewService.patchData(url, {
            imageSrc: element.imageSrc
          }).subscribe(() => {
            this.dataService.setDefaultPagination();
            this.reloadFilteredData();
          });
        }
      });
    } else if (action.label === 'ActivateDeActivate') {
      const dialogDataForActivateDeActivateConfirmation = this.listViewData.metaData.getDialogDataForActiveDeActive(
        element.active
      );
      const url = `${dialogDataForActivateDeActivateConfirmation.url}/${element.id}/active`;
      const dialogRef = this.dataService.openDialog(
        dialogDataForActivateDeActivateConfirmation
      );
      dialogRef.subscribe(result => {
        if (result) {
          this.patchData({ active: element.active ? 0 : 1 }, url);
        }
      });
    } else if (action.label === 'updateState') {
      const dialogDataForActivateDeActivateConfirmation = this.listViewData.metaData.getDialogDataForActiveDeActive(
        element.isActive
      );
      const url = `${dialogDataForActivateDeActivateConfirmation.url}`;
      const dialogRef = this.dataService.openDialog(
        dialogDataForActivateDeActivateConfirmation
      );
      dialogRef.subscribe(result => {
        if (result) {
          this.listViewService.putData(url, { userId: element.userId, isActive: element.isActive ? 0 : 1 }).subscribe(res => {
            this.reloadFilteredData();
          });
        }
      });
    } else if (action.label === 'Edit Movement') {
      const url = `${this.listViewData.metaData.editRoute}`
        + `/${element.looseId}`
        + `/${element.fromLocationId}`
        + `/${element.toLocationId}`;
      this.router.navigate([ url ]);
    } else if (action.label === 'updateCrate') {
      const dialogDataForActivateDeActivateConfirmation = this.listViewData.metaData.getDialogDataForActiveDeActive(
        element.isActive
      );
      const url = `${dialogDataForActivateDeActivateConfirmation.url}/${element.id}`;
      const dialogRef = this.dataService.openDialog(
        dialogDataForActivateDeActivateConfirmation
      );
      dialogRef.subscribe(result => {
        if (result) {
          this.listViewService.putData(url, { isActive: element.isActive ? 0 : 1, hubId: this.dataService.globalFilter.apiValue.hubId }).subscribe(res => {
            this.reloadFilteredData();
          });
        }
      });
    } else if (action.label === 'ActivateSociety') {
      const dialogDataForActivateDeActivateConfirmation = this.listViewData.metaData.getDialogDataForActiveDeActive(
        element.active
      );
      let urlPath = '';
      if (element.active) {
        urlPath = 'societies/deactivate';
      } else {
        urlPath = 'societies/activate';
      }
      const url = `${urlPath}?societyId=${element.id}`;
      if (!element.active) {
        dialogDataForActivateDeActivateConfirmation[ 'title' ] = 'Activate this society';
        dialogDataForActivateDeActivateConfirmation[ 'message' ] = '';
      }
      const dialogRef = this.dataService.openDialog(
        dialogDataForActivateDeActivateConfirmation
      );
      dialogRef.subscribe(result => {
        if (result) {
          this.patchData({ active: element.active ? 0 : 1 }, url);
        }
      });
    } else if (action.label === 'ActivatePpdBanner') {
      const dialogDataForActivateDeActivateConfirmation =
        this.listViewData.metaData.getDialogDataForActiveDeActive(
          element.active
        );
      let urlPath = '';
      if (element.id) {
        urlPath = `ppd/promotions/banners/${element.id}/active`;
      }
      if (!element.active) {
        dialogDataForActivateDeActivateConfirmation[ 'title' ] =
          'Activate Ppd Banner';
        dialogDataForActivateDeActivateConfirmation[ 'message' ] = 'On press yes this banner will be activate.';
      } else {
        dialogDataForActivateDeActivateConfirmation[ 'title' ] =
          'Deactivate Ppd Banner';
        dialogDataForActivateDeActivateConfirmation[ 'message' ] = 'On press yes this banner will be deactivate.';
      }
      const dialogRef = this.dataService.openDialog(
        dialogDataForActivateDeActivateConfirmation
      );
      dialogRef.subscribe((result) => {
        if (result) {
          this.patchData({ active: element.active ? 0 : 1 }, urlPath);
        }
      });
    } else if (action.label === 'Feature Change') {
      const dialogDataForActivateDeActivateConfirmation = this.listViewData.metaData.getDialogDataForActiveDeActive(
        element.isActive
      );
      const url = `ppd/features/${element.id}`;
      if (!element.isActive) {
        dialogDataForActivateDeActivateConfirmation[ 'title' ] = 'Activate this feature';
        dialogDataForActivateDeActivateConfirmation[ 'message' ] = '';
      } else {
        dialogDataForActivateDeActivateConfirmation[ 'title' ] = 'Deactivate this feature';
        dialogDataForActivateDeActivateConfirmation[ 'message' ] = '';
      }
      const dialogRef = this.dataService.openDialog(
        dialogDataForActivateDeActivateConfirmation
      );
      dialogRef.subscribe(result => {
        if (result) {
          const dataToSend = {
            dataKey: element.dataKey,
            dataValue: element.dataValue,
            isActive: element.isActive ? 0 : 1,
            name: element.name
          };
          this.listViewService.putData(url, dataToSend).subscribe(res => {
            if (res == null) {
              this.dataService.refreshList.next(true);
            }
          });

          // this.patchData({ active: element.isActive ? 0 : 1 }, url);
        }
      });
    } else if (action.label === 'Activation Toggle') {
      const dialogDataForActivateDeActivateConfirmation = this.listViewData.metaData.getDialogDataForActiveDeActive(
        element.active
      );
      const url = `${AppWebRoutes.MARKETING.campaign}/active/${element.id}`;
      const dialogRef = this.dataService.openDialog(
        dialogDataForActivateDeActivateConfirmation
      );
      dialogRef.subscribe(result => {
        if (result) {
          this.listViewService.putData(url, { active: element.active ? 0 : 1 }).subscribe(res => {
            if (res == null) {
              this.dataService.refreshList.next(true);
            }
          });
        }
      });
    } else if (action.label === 'Activation Campaign') {
      const dialogDataForActivateDeActivateConfirmation = this.listViewData.metaData.getDialogDataForActiveDeActive(
        element.isLive
      );
      const url = `${AppWebRoutes.RECURRING.recurring}/${element.id}/active`;
      const dialogRef = this.dataService.openDialog(
        dialogDataForActivateDeActivateConfirmation
      );
      dialogRef.subscribe(result => {
        if (result) {
          this.listViewService.patchData(url, {}).subscribe(res => {
            if (res == null) {
              this.dataService.refreshList.next(true);
            }
          });
        }
      });
    } else if (action.label === 'Recurring Campaign slab') {
      this.router.navigate([
        `/marketing/recurring-campaign-slab`,
        element.id
      ]);
    } else if (action.label === 'Edit recurring campaign') {
      this.router.navigate([
        `marketing/recurring-campaign/edit/${this._route.params[ 'value' ].id}/${element.startDate}/${element.endDate}` ]);
    } else if (action.label === 'Inactivate') {
      const dialogRef = this.dataService.openDialog(
        this.listViewData.metaData.delete
      );
      dialogRef.subscribe(result => {
        if (result) {
          const dataToSend = {
            productId: element.productId,
            hubId: element.hubId,
            stickerName: element.stickerName
          };
          this.patchData(dataToSend, this.listViewData.metaData.delete.url);
        }
      });
    } else if (action.label === 'Activate') {
      const dialogDataForActivateDeActivateConfirmation = this.listViewData.metaData.getDialogDataForActiveDeActive(
        element.active
      );
      const url = `${dialogDataForActivateDeActivateConfirmation.url}/${element.subCategoryId}/hubs/${element.hubId}/update-active`;
      const dialogRef = this.dataService.openDialog(
        dialogDataForActivateDeActivateConfirmation
      );
      dialogRef.subscribe(result => {
        if (result) {
          this.patchData({ active: element.active ? 0 : 1 }, url);
        }
      });
    } else if (action.label === 'Update Sort') {
      this.listViewData.metaData.sortOrder[ 'sortOrderValue' ] =
        element.sortOrder;
      const dialogRef = this.dataService.openDialog(
        this.listViewData.metaData.sortOrder
      );
      dialogRef.subscribe(result => {
        if (result) {
          this.sortOrder(result, element);
        }
      });
    } else if (action.label === 'Update data value') {
      if (appConstants.referImageKeys.includes(element[ 'dataKey' ])) {
        element[ 'actionLabel' ] = action.label;
        this.dialogUpdate.emit({ data: element, action: action });
      } else {
        this.listViewData.metaData.sortOrder[ 'dataValue' ] =
          element.dataValue;
        const dialogRef = this.dataService.openDialog(
          this.listViewData.metaData.sortOrder
        );
        dialogRef.subscribe(result => {
          if (result) {
            const url = `${this.listViewData.metaData.sortOrder.url}/${element.id}`;
            this.listViewService.putData(url, { dataValue: JSON.stringify(result) }).subscribe(res => {
              this.dataService.refreshList.next(true);
            });
          }
        });
      }
    } else if (action.label === 'Validity' || action.label === 'Campaign Validity') {
      this.router.navigate([
        this.listViewData.metaData.manageRoute,
        'validity',
        element.id
      ]);
    } else if (action.label === 'Recurring Validity') {
      this.router.navigate([
        this.listViewData.metaData.manageRoute,
        'campaign',
        element.id,
        'date',
      ]);
    } else if (action.label === 'Update Campaign Product') {
      this.router.navigate([
        `marketing/recurring-upload`,
        element.id
      ]);
    } else if (action.label === 'Delivery Type') {
      this.router.navigate([
        this.listViewData.metaData.manageRoute,
        'delivery-type',
        element.id
      ]);
    } else if (action.label === 'Edit Delivery Type') {
      this.router.navigate([
        this.listViewData.metaData.manageRoute,
        element.cityId,
        'edit',
        element.id
      ]);
    } else if (action.label === 'Edit Hub Data') {
      this.router.navigate([ this.listViewData.metaData.manageRoute, element.hubId, 'edit', element.id ]);
    } else if (action.label === 'Delivery Type') {
      this.router.navigate([
        this.listViewData.metaData.manageRoute,
        'delivery-type',
        element.id
      ]);
    } else if (action.label === 'Edit Delivery Type') {
      this.router.navigate([
        this.listViewData.metaData.manageRoute,
        element.cityId,
        'edit',
        element.id
      ]);
    } else if (action.openDialogOnClick) {
      element[ 'actionLabel' ] = action.label;
      this.dialogUpdate.emit({ data: element, action: action });
    } else if (action.label === 'Allocate Team Supplier' || action.label === 'towerRequest') {
      this.dialogUpdate.emit({ data: element });
    } else if (action.label === 'Edit Recieve PO') {
      let route = this.listViewData.metaData.editRoute;
      if (!element.grnNumber) {
        element.grnNumber = -1;
        route = this.listViewData.metaData.manageRoute;
      }
      this.router.navigate([
        route,
        element.poId || element.poNumber,
        element.grnNumber
      ]);
    } else if (action.label === 'settingParams') {
      this.dataService.pageName = element.paramKey;
      this.dataService.setLocalStorage(
        environment.localStorageKeys.PAGENAME,
        element.paramKey
      );

      this.router.navigate([
        this.listViewData.metaData.editRoute,
        element.id,
        element.paramValue
      ]);
    } else if (action.label === 'Sms') {
      this.router.navigate([ action.operation[ 'url' ] ]);
    } else if (action.label === 'Send SMS') {
      this.router.navigate([ action.operation[ 'url' ], element.id ]);
    } else if (action.label === 'addHoliday') {
      this.router.navigate([ 'catalog/vendor', 'add-holiday', element.id ]);
    } else if (action.label === 'cutoffTime') {
      this.router.navigate([ 'catalog/vendor', 'cutoff-time', element.id ]);
    } else if (action.label === 'teamConfig') {
      this.router.navigate([ 'location/team', 'config', element.id ]);
    } else if (action.label === 'AddUserRole') {
      this.router.navigate([ 'user/user', 'add-user-role', element.id ]);
    } else if (action.label === 'viewDetails') {
      this.router.navigate([ action.operation.url, element.id ]);
    } else if (action.label === 'Email') {
      const url = action.operation.url + '?month=' + element.value;
      this.listViewService.getData(url).subscribe(response => {
        this.dataService.openSnackBar(action.operation.message);
      });
    } else if (action.label === 'Download') {
      if (action.operation.url === 'list/export/product') {
        const url = action.operation.url;
        this.listViewService.exportCsv(url,
          this.listViewData.metaData.header.replace(/\s/g, ''),
          this.dataService.globalFilter.uivalue.hubId,
          { listId: element.id },
          this.listViewData.metaData.removeGlobalFilter);
      } else {
        const url = action.operation.url + '?month=' + element.value;
        this.listViewService.downloadPdf(url, element.month);
      }
    } else if (action.label === 'View') {
      const url = action.operation.url + '?month=' + element.value;
      this.listViewService.downloadPdf(url, element.month, true);
    } else if (action.label === 'Copy') {
      this.router.navigate([
        this.listViewData.metaData.manageRoute,
        element.id
      ]);
    } else if (action.label === 'Copy Campaign') {
      this.router.navigate([
        'marketing/campaigns/copy/copy_campaign',
        element.id
      ]);
    } else if (action.label === 'Copy Link') {
      let textarea;
      textarea = document.createElement('textarea');
      textarea.style.height = '0px';
      textarea.style.left = '-100px';
      textarea.style.opacity = '0';
      textarea.style.position = 'fixed';
      textarea.style.top = '-100px';
      textarea.style.width = '0px';
      document.body.appendChild(textarea);

      // Set and select the value (creating an active Selection range).
      textarea.value = `${environment.file_url}${action.componentPath || ''}/${element.image
        }`;
      textarea.select();
      document.execCommand('copy');
      this.dataService.openSnackBar('Copied to the Clip Board', 'success');
    } else if (action.label === 'Recurring') {
      this.router.navigate([ 'order/recurring', 'brandId', element.id ]);
    } else if (action.label === 'ExecuteCron') {
      const dialogRef = this.dataService.openDialog(
        this.listViewData.metaData.confirmation
      );
      dialogRef.subscribe(result => {
        if (result) {
          const requestBody = {
            jobKey: element.key,
            hubId: this.dataService.globalFilter.apiValue.hubId
          };
          const url = action.operation.url;
          this.listViewService.postData(url, requestBody).subscribe();
        }
      });
    } else if (action.label === 'cancelSuperRecharge') {

      const dialogRef = this.dataService.openDialog(
        this.listViewData.metaData.confirmation
      );
      dialogRef.subscribe(result => {
        if (result) {
          this.listViewService.editDataPut(AppWebRoutes.TRANSACTION.cancelSuperRecharge, null, element.id).subscribe((res: any) => {
          });
        }
      });

    } else if (action.label === 'updateVariant') {
      const dialogRef = this.dataService.openDialog(this.listViewData.metaData.subcategoryViewUpdateDialogBox);
      dialogRef.subscribe(result => {
        const apiUrl = `${AppWebRoutes.SUBCATEGORIES.subcategories}/${element.subCategoryId}/hubs/${this.dataService.globalFilter.apiValue.hubId}/update-variants-view`;
        this.listViewService.patchData(apiUrl, {
          variantsView: result.value.variantsView
        }).subscribe(res => { });
      });
    } else if (action.label === 'categoryOos') {
      this.listViewData.metaData.confirmation = {
        title: element[ 'forcedOos' ] ? 'Mark this category in stock' : 'Mark this category out of stock',
        text: '',
        why: 'update'
      };
      const dialogRef = this.dataService.openDialog(this.listViewData.metaData.confirmation);
      dialogRef.subscribe(result => {
        if (result) {
          this.listViewService.postData(AppWebRoutes.CATEGORIES.categoryOos, {
            hubId: this.dataService.globalFilter.apiValue.hubId,
            forcedOos: element[ 'forcedOos' ] ? 0 : 1,
            categoryIds: [ element.categoryId ]
          }).subscribe(res => {
            if (res === null || res) {
              this.dataService.refreshList.next(true);

            }
          });
        }
      });
    } else if (action.label === 'deleteCard' || action.isPatchRequest) {
      let apiUrl = '';
      let dialogRef;
      if (element.cardToken) {
        apiUrl = `${AppWebRoutes.TRANSACTION.deleteCard}/${element.cardToken}`;
        dialogRef = this.dataService.openDialog(this.listViewData.metaData.confirmation);
      } else {
        switch (action.label) {
          case 'Approve':
            apiUrl = `${this.listViewData.metaData.confirmation.url}/${element.id}`;
            dialogRef = this.dataService.openDialog(this.listViewData.metaData.confirmation);
            break;
          case 'Reject':
            apiUrl = `${this.listViewData.metaData.reject.url}/${element.id}`;
            dialogRef = this.dataService.openDialog(this.listViewData.metaData.reject);
            break;
          case 'Disable':
            apiUrl = `${this.listViewData.metaData.disable.url}/${element.id}`;
            dialogRef = this.dataService.openDialog(this.listViewData.metaData.disable);
            break;
        }
      }
      dialogRef.subscribe(result => {
        if (result) {
          this.listViewService.patchData(apiUrl, null).subscribe(res => {
            if (res === null || res) {
              this.dataService.refreshList.next(true);
            }
          });
        }
      });
    } else if (action.label === 'Cash Back Ledger') {
      this.sharedCustomerService.openMbPassLedger(element.cashbackLedger);
    } else if (action.label === 'Recurring Campaign bulk Upload') {
      console.log('inside if')
      this.router.navigate([
        `marketing/recurring-campaign-product-list`,
        element.id
      ]);
    } else if (action.label === 'PPD customer navigation') {
      this.router.navigate([ `/customer/edit/${element.customerId}/${element.orderId}` ]);
    } else if (action.label === 'Audio recording') {
      this.dialog.open(anfDialogComponent, {
        width: 'auto',
        data: { 'customerId': element[ 'customerId' ], 'date': element[ 'date' ] },
        autoFocus: false
      });
    } else if (action.label === 'Edit In-Out KMs') {
      this.sharedPPDService.savedailyReportToLocal(element.id + '', JSON.stringify(element));
      this.router.navigate([
        `ppd/transport/daily-report-curd`,
        element.id
      ]);
    } else if (action.label === 'Edit City-Hub') {
      this.editAction.next(element);
    } else if (action.label === 'Edit Product Return') {
      this.router.navigate([
        this.listViewData.metaData.editRoute,
        element.orderProductReturnId,
        element.orderProductHubReturnId ? element.orderProductHubReturnId : 0,
        element.isWrong ? 0 : element.productId,
      ]);
    } else if (action.label === 'Edit Parent Product') {
      this.router.navigate([
        this.listViewData.metaData.manageRoute,
        element.id
      ]);
    }
  }

  EditActionSet (element, action) {
    if (action.operation && action.operation[ 'id' ]) {
      element.id = element[ action.operation[ 'id' ] ];
    }
    if (action.operation && action.operation[ 'name' ]) {
      element.name = element[ action.operation[ 'name' ] ];
    }
    if (element.name) {
      this.dataService.pageName = element.name;
      this.dataService.setLocalStorage(
        environment.localStorageKeys.PAGENAME,
        element.name
      );
    }
    if (element.reason) {
      this.dataService.pageName = element.reason;
      this.dataService.setLocalStorage(
        environment.localStorageKeys.PAGENAME,
        element.reason
      );
    }
    if (action.label === 'Edit') {
      this.dataService.resetPagination = true;
      if (action.operation === 'editVoucher') {
        return `/${this.listViewData.metaData.editRoute}/${element.id}/city/${this.dataService.globalFilter.apiValue.cityId}`;
      }

      if (action.operation && action.operation[ 'dependentOn' ]) {

        return `/${this.listViewData.metaData.editRoute}/${element[ action.operation[ 'dependentOn' ] ]
          }/${element.id}`;

      } else {
        if (element[ 'isBundle' ] === 1) {
          return `/${this.listViewData.metaData.viewRoute}/${element.id}`;
        } else if (element.offerId) {
          return `/${this.listViewData.metaData.editRoute}/${element.offerId}/${element.benefitId}`;
        } else {
          return `/${this.listViewData.metaData.editRoute}/${element.id ||
            element.vendorId}`;
        }
      }
    } else if (action.label === 'EditProduct') {
      if (element.isBundle) {
        return `/${this.listViewData.metaData.editRoute}/${this.dataService.globalFilter.apiValue.hubId}/bundle/${element.id}/edit`;
      } else {
        return `/${this.listViewData.metaData.editRoute}/${this.dataService.globalFilter.apiValue.hubId}/product/${element.id}/edit`;
      }
    } else if (action.label === 'settingParams') {
      this.dataService.pageName = element.paramKey;
      this.dataService.setLocalStorage(
        environment.localStorageKeys.PAGENAME,
        element.paramKey
      );
      return `/${this.listViewData.metaData.editRoute}/${element.id}/${element.paramValue}`;
    } else if (action.label === 'Feature Properties') {
      return `/${this.listViewData.metaData.editRoute}/${this.dataService.globalFilter.apiValue.hubId}/product/${element.id}/edit`;

    }
  }
  markAsDone (element) {
    const url = `${AppWebRoutes.CATALOG.changeRequestApprove}/${element.id}/approve`;
    const dialogRef = this.dataService.openDialog(
      this.listViewData.metaData.delete
    );
    dialogRef.subscribe(result => {
      if (result) {
        this.listViewService
          .editData(url, null, null)
          .subscribe((success: Response) => {
            this.dataService.setDefaultPagination();
            this.reloadFilteredData();
          });
      }
    });
  }

  applyFilter (filterValue: string) {
    this.listViewData.listData.filter = filterValue.trim().toLowerCase();
  }

  deleteData (id, element) {
    let url;
    if (this.listViewData.metaData.delete.deleteById) {
      id = element[ this.listViewData.metaData.delete.deleteById ];
    }
    if (this.listViewData.mapping === 'clusters') {
      url = `${this.listViewData.metaData.delete.url}/${element.societyId}/clusters`;
      this.dataService.refreshList.next(true);
    } else if (this.listViewData.mapping === 'teams') {
      url = `${this.listViewData.metaData.delete.url}/${element.teamId}/clusters`;
    } else if (this.listViewData.mapping === 'cities') {
      this.dataService.refreshList.next(true);
      url = `${this.listViewData.metaData.delete.url}/${this._route.params[ 'value' ].id}/cities`;
    } else if (this.listViewData.mapping === 'productHub') {
      if (element[ 'isBundle' ] === 1) {
        url = `${this.listViewData.metaData.delete.url}/${element.productId}/hubs/${this.dataService.globalFilter.apiValue.hubId}/bundles`;
        id = '';
      } else {
        url = `${this.listViewData.metaData.delete.url}/${element.productId}/hubs`;
      }
    } else if (this.listViewData.mapping === 'sub-category') {
      url = `${this.listViewData.metaData.delete.url}/${element.subCategoryId}/hubs`;
    } else if (this.listViewData.mapping === 'category') {
      url = `${this.listViewData.metaData.delete.url}/${element.categoryId}/hubs`;
    } else if (this.listViewData.mapping === 'vendor') {
      url = `${this.listViewData.metaData.delete.url}/${element.vendorId}/hubs`;
    } else if (this.listViewData.mapping === 'tomorrow_basket') {
      this.optionParams = {
        sendMessage: true,
        productQuantity: element.productQuantity
      };
      url = `${this.listViewData.metaData.delete.url}/${element.productId}/date`;
      id = this.listViewData.date;
    } else if (this.listViewData.mapping === 'editVoucherCity') {
      url = `${this.listViewData.metaData.delete.url}/${element.voucherId}/city`;
      id = this.dataService.globalFilter.apiValue.cityId;
    } else if (this.listViewData.mapping === 'recurring_order') {
      url = `${this.listViewData.metaData.delete.url}/${element.recOrderId}/stop`;
      id = '';
    } else if (this.listViewData.mapping === 'hub_revenue') {
      url = `${this.listViewData.metaData.delete.url}/${element.id}`;
      id = '';
    } else if (this.listViewData.mapping === 'auto_add_sample') {
      url = `${this.listViewData.metaData.delete.url}/${element.offerId}`;
      id = '';
      this.optionParams = {
        benefitId: element.benefitId
      };
    } else {
      url = this.listViewData.metaData.delete.url;
    }
    if (this.listViewData.metaData.delete.appendIdIgnore) {
      id = '';
    }
    if (url === 'products/catalogs' && element[ 'isBundle' ] === 1) {
      url = `${this.listViewData.metaData.delete.url}/bundles`;
    }
    if (this.listViewData.mapping === "city-hub" && this.listViewData?.metaData?.delete?.localDeleteOnly) {
      this.localDeleteAction.next(element);
      return;
    }
    this.listViewService
      .deleteData(url, id, this.optionParams)
      .subscribe(() => {
        this.dataService.setDefaultPagination();
        this.reloadFilteredData();
      });
  }

  sortOrder (val, element) {
    let url;
    const dataToSend = { sortOrder: val };
    if (this.listViewData.mapping === 'clusters') {
      url =
        this.listViewData.metaData.sortOrder.url +
        '/' +
        `${element.id}/update-sort`;
    } else if (this.listViewData.mapping === 'teams') {
      url = `${this.listViewData.metaData.sortOrder.url}/${this._route.params[ 'value' ].id}/clusters/${element.clusterId}/update/sort`;
    } else if (this.listViewData.mapping === 'category') {
      url = `${this.listViewData.metaData.sortOrder.url}/${element.categoryId}/hubs/${element.hubId}/update`;
    } else if (this.listViewData.mapping === 'sub-category') {
      url = `${this.listViewData.metaData.sortOrder.url}/${element.subCategoryId}/hubs/${element.hubId}/update-sort`;
    }
    this.listViewService.patchData(url, dataToSend).subscribe(() => {
      this.dataService.setDefaultPagination();
      this.reloadFilteredData();
    });
  }

  exportCsv (url, check = false) {
    if (this.listViewData.checkBundle && check) {
      url = AppWebRoutes.CATALOG.productHubBundleExport;
    }
    if (this.dataService.searchFilters.filters[ 'statusOfInviteCode' ]) {
      url =
        url +
        '/' +
        this.dataService.searchFilters.filters[ 'statusOfInviteCode' ];
    }
    const params = <any>this._route.params;
    let hubName = '';
    if (params.value.hubId) {
      this.listViewService
        .getDataById(AppWebRoutes.HUBS.hubs, params.value.hubId)
        .subscribe((success: Response) => {
          hubName = success[ 'name' ];
          this.listViewService.exportCsv(
            url,
            this.listViewData.metaData.header.replace(/\s/g, ''),
            hubName,
            this.listViewData.metaData.viewExportParams,
            this.listViewData.metaData.removeGlobalFilter
          );
        });
    } else {
      this.listViewService.exportCsv(
        url,
        this.listViewData.metaData.header.replace(/\s/g, ''),
        hubName,
        this.listViewData.metaData.viewExportParams,
        this.listViewData.metaData.removeGlobalFilter
      );
    }
  }

  navigateToView (element, row) {
    this.dataService.goToViewPage = true;
    this.dataService.resetPagination = false;
    if (row.linkId) {
      if (row.navigateTo === 'ppd/deliverysociety') {
        return `/${row.navigateTo}/${element[ row.linkId ]}/${element.clusterType}`;
      }
      if (row.navigateTo === 'ppd/deliverytower') {
        return `/${row.navigateTo}/${element[ row.linkId ]}/${'droppointId' in element ? CLUSTER_TYPE.GEOFENCE : CLUSTER_TYPE.NORMAL}`;
      }
      return `/${row.navigateTo}/${element[ row.linkId ]}`;
    } else if (row.linkArray) {
      const navData = [ row.navigateTo ];
      row.linkArray.forEach(key => {
        navData.push(element[ key ]);
      });
      const navigatePath = navData.join('/');
      return `/${navigatePath}`;
    }
  }

  formDetails (element, row) {
    if (row.key === 'Images') {
      this.dialog.open(ProductReturnImagesComponent, {
        data: { clusterId: element.clusterId, hubId: element.hubId, date: element.date, productId: element.productId }
      });
    } else {
      this.dataService.dataToSendForViewDetails = element;
      this.router.navigate([ `/${row.navigateTo}` ]);
    }
  }

  openContactDetailModal (element, row) {
    const contact = element.rwaDirectoryList[ parseInt(row.linkId) ];
    this.dialog.open(ContactDetailsComponent, {
      width: 'auto',
      data: contact
    });
  }

  navigateToPage (element, row) {
    if (element[ row.linkId ]) {
      if (row.navigateTo === 'order_transaction') {
        switch (element.type) {
          case 'order':
            return `/order/view/${element[ 'data' ]}`;

          case 'purchase':
            return `/transaction/view/${element[ 'data' ]}`;

          case 'order_refund':
            return `/order/view/${element[ 'data' ]}`;

          case 'cashback':
            if (element.details && element.details === 'Subscription savings')
              return `/order/benefits/${element[ 'data' ]}`;
            else return `/order/view/${element[ 'data' ]}`;

          case 'refund_revert':
            return `/order/view/${element[ 'data' ]}`;
        }
      } else if (row.navigateTo === 'catalog/hub') {
        let url = '';
        if (
          element[ 'isBundle' ] === 1 ||
          this.listViewData.mapping === 'bundleOrder'
        ) {
          url = `catalog/hub/${this.dataService.globalFilter.apiValue.hubId
            }/bundle/${element[ row.linkId ]}/view`;
        } else {
          url = `catalog/hub/${this.dataService.globalFilter.apiValue.hubId
            }/product/${element[ row.linkId ]}/view`;
        }
        return `/${url}`;
      } else if (row.navigateTo === 'master/product-catalog/view') {
        if (element[ 'isBundle' ] === 1) {
          return `/${row.navigateTo}/${element[ 'isBundle' ]}/${element[ row.linkId ]
            }`;
        } else {
          return `/${row.navigateTo}/${element[ row.linkId ]}`;
        }
      } else if (row.navigateTo === 'master/super-category/view-category') {
        return `/${row.navigateTo}/${element[ row.linkId ]}/${element.name}`;
      } else if (row.navigateTo === 'catalog/super-category/view-category') {
        return `/${row.navigateTo}/${element[ row.linkId ]}/${element.name}`;
      } else if (row.navigateTo === 'location/map') {
        return `/${row.navigateTo}/${element[ row.linkId ]}/${element.lat ? element.lat : 77.019135}/${element.lng ? element.lng : 28.481216}`;
      } else if (row.navigateTo === 'location/clusters/society' && element.clusterType === CLUSTER_TYPE.GEOFENCE) {
        return `/${row.navigateTo}/${element[ row.linkId ]}/${CLUSTER_TYPE.GEOFENCE}`;
      } else {
        return `/${row.navigateTo}/${element[ row.linkId ]}`;
      }
    }
  }

  navigateToNewTab (element, row) {
    if (element[ row.linkId ]) {
      let url = '';
      if (row.navigateTo === 'master/product-catalog/view') {
        if (element[ 'isBundle' ] === 1) {
          url = `${row.navigateTo}/${element[ 'isBundle' ]}/${element[ row.linkId ]}`;
        } else {
          url = `${row.navigateTo}/${element[ row.linkId ]}`;
        }
      } else {
        url = `/${row.navigateTo}/${element[ row.linkId ]}`;
      }
      url && window.open(environment.base_admin_url + url, "_blank");
    }
  }

  navigateToURL (element) {
    if (element.issueId) {
      this.winRef
        .nativeWindow
        .open(`${environment.issueRedirection}${element.issueId}`);
    }
  }

  patchData (formValue, url) {
    this.listViewService.patchData(url, formValue).subscribe(() => {
      this.dataService.setDefaultPagination();
      this.reloadFilteredData();
    });
  }

  checkboxValue (ele, event) {

    let id = 0;
    if (this.router.url.includes('retention-list')) {
      this.retentionDataSet(ele, event);
    } else if (this.router.url.includes('not-assigned')) {
      let index = this.listViewData.groupPrivileges.indexOf(ele.id);
      if(index === -1) {
        this.listViewData.groupPrivileges.push(ele.id);
      } else {
        this.listViewData.groupPrivileges.splice(index, 1);
      }
    } else {
      let index = -1;
      if (this.listViewData.groupPrivileges) {
        index = this.listViewData.groupPrivileges.indexOf(ele.id);
      }
      if (event.checked && index === -1) {
        this.listViewData.groupPrivileges.push(ele.id);
      } else if (!event.checked && index !== -1) {
        this.listViewData.groupPrivileges.splice(index, 1);
      }
      console.log(this.listViewData.groupPrivileges, ' bottom');
    }

  }

  retentionDataSet (element, event) {
    let index = -1;

    this.listViewData.groupPrivileges.forEach(item => {
      if (this.listViewData.groupPrivileges) {
        index = this.listViewData.groupPrivileges.indexOf(element.customerId);
      }
    })

    if (event.checked && index === -1) {
      if (element?.issueId) {
        this.listViewData.groupPrivileges.push({
          customerId: element.customerId,
          issueId: element.issueId,
        });
      } else {
        this.listViewData.groupPrivileges.push({
          customerId: element.customerId,
        });
      }

    } else if (!event.checked && index !== -1) {
      this.listViewData.groupPrivileges.splice(index, 1);
    }

  }

  selectAll (event) {
    let index, id;
    this.listViewData.listData.data.forEach(ele => {
      ele[ 'checkboxValue' ] = event.checked;
      id = ele[ 'id' ];
      index = this.listViewData.groupPrivileges.indexOf(id);
      if (index === -1) {
        this.listViewData.groupPrivileges.push(id);
      }
    });
  }

  clearSearchData (input: ElementRef) {
    input.nativeElement.value = '';
    this.applyFilter('');
    this.inputClassFocus = false;
  }
  submitData (element, action) {
    if (!element[ action.operation.name ]) {
      const dataToSend = {
        productId: element[ 'productId' ],
        hubId: this.dataService.globalFilter.apiValue.hubId,
        clusterId: element[ 'clusterId' ],
        acceptQty: element[ 'acceptQty' ]
      };
      this.listViewService.postData(AppWebRoutes.PPD.productReturn, dataToSend).subscribe((res) => {
        element[ action.operation.name ] = element[ 'acceptQty' ];
      });
    }
  }

  onCellClick (element, headerKey) {
    this.cellClickAction.emit({ data: element, headerKey: headerKey });
  }

  ngOnDestroy () {
    this.dataService.isListView = false;
    this.dataService.removeFromLocalStorage(
      environment.localStorageKeys.PAGESIZE
    );
    this.dataService.setDefaultPagination();
  }
}
