import { Component, ElementRef, Input, NgZone, OnInit, ViewChild, Renderer2, Output, EventEmitter } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { FormGroup } from '@angular/forms';
import { DataService } from '../../services/data/data.service';
import { inputComponentActions } from '../../constants/shared-constant';
import { MapPins } from '../../interface/interface';
declare var google;

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: [ './google-map.component.scss' ]
})
export class GoogleMapComponent implements OnInit {
  @Input() _google;
  @Input() form: FormGroup;
  @Input() loadMapOnly: boolean = false;
  @Input() mapList: Array<MapPins>;
  public latitude = 28.4595;
  public longitude = 77.0266;
  public zoom: number;
  public draggable: boolean = true;
  detailsFromMap = {};
  map;
  mapClickListener;
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;
  public options = {
    componentRestrictions: { country: 'in' }
  };

  constructor (
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private dataService: DataService,
    private renderer: Renderer2,
    private zone: NgZone
  ) { }

  ngOnInit () {
    this.zoom = 12;
    if (!this.loadMapOnly) {
      setTimeout((_)=>{
        if (this._google.event) {
          this.renderer.listen(this.searchElementRef.nativeElement, this._google.event, this.callBack);
          this.loadMapApi();
        }
      },500)
      // set current position on map
      if (this._google.locationType) {
        this.options[ 'types' ] = [];
        this.options[ 'types' ].push(this._google.locationType);
      }
      // this.setCurrentPosition();
      this.form.controls[ 'latitude' ].valueChanges.subscribe(value => {
        this.latitude = value;
      });
      this.form.controls[ 'longitude' ].valueChanges.subscribe(value => {
        this.longitude = value;
      });
      this.loadMapApi();
    } else {
      this.mapList[ 0 ].latitude && (this.latitude = this.mapList[ 0 ].latitude);
      this.mapList[ 0 ].longitude && (this.longitude = this.mapList[ 0 ].longitude);
    }
  }

  public markerDragEnd (e) {
    this.latitude = e.latLng.lat();
    this.longitude = e.latLng.lng();
    this.dataService.detailsFromMap[ 'latitude' ] = this.latitude;
    this.dataService.detailsFromMap[ 'longitude' ] = this.longitude;
  }

  triggermarkerDragEnd (e) {
    this.mapList[ 0 ].latitude = e.latLng.lat();
    this.mapList[ 0 ].longitude = e.latLng.lng();
    this.latitude = this.mapList[ 0 ].latitude;
    this.longitude = this.mapList[ 0 ].longitude;
  }

  callBack = () => {
    switch (this._google.action) {
      case inputComponentActions.coordinates:
        if (this._google.target instanceof Array) {
          for (const target in this._google.target) {
            this.form.controls[ this._google.target[ target ] ].patchValue(
              this[ this._google.target[ Number(target) ] ].toFixed(6)
            );
          }
        }
    }
  };

  // To be used in future (tentative)

  // private getAddress() {
  //   // const address = new google.maps.LatLng(this.latitude, this.longitude);
  //   const geocoder = new google.maps.Geocoder();
  //   const request = { latLng: new google.maps.LatLng(this.latitude, this.longitude) };
  //   geocoder.geocode(request, (results, status) => {
  //     if (status === google.maps.GeocoderStatus.OK) {
  //       this.form.controls[this._google.key].patchValue(results[0].formatted_address);
  //     }
  //   });
  // }

  public mapReadyHandler (map: google.maps.Map): void {
    this.map = map;
    this.mapClickListener = this.map.addListener('click', (e: google.maps.MouseEvent) => {
      this.zone.run(() => {
        this.latitude = e.latLng.lat();
        this.longitude = e.latLng.lng();
        this.zoom = 18;
      });
    });
  }

  public mapClicked ($event: google.maps.MouseEvent) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.zoom = 18;
  }

  private loadMapApi () {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, this.options);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place = autocomplete.getPlace();
          this.dataService.detailsFromMap[ 'name' ] = place.name;
          this.form.controls[ this._google.key ].patchValue(place.name);
          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.dataService.detailsFromMap[ 'latitude' ] = this.latitude;
          this.longitude = place.geometry.location.lng();
          this.dataService.detailsFromMap[ 'longitude' ] = this.longitude;
          this.zoom = 18;
        });
      });
    });
  }

  private setCurrentPosition () {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    }
  }

  public ngOnDestroy (): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
  }
}
