import {
    Component,
    Inject,
    OnInit
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppWebRoutes } from 'src/app/core/constants/app-web-routes';
import { SharedPPDService } from 'src/app/ppd/services/shared-ppd.service';


@Component({
    selector: 'app-anf-dialog',
    templateUrl: './anf-dialog.component.html',
    styleUrls: [ './anf-dialog.component.scss' ]
})
export class anfDialogComponent implements OnInit {
    anfAudioList = [];
    showPop: boolean = false;
    constructor (
        private sharedPpdService: SharedPPDService,
        public dialog: MatDialog,
        public permissionService: NgxPermissionsService,
        public dialogRef: MatDialogRef<anfDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {

    }
    ngOnInit () {
        this.getAudioData();
    }
    getAudioData () {
        this.sharedPpdService.getAllList(`${AppWebRoutes.PPD.anfCallLogs}?customerId=${this.data.customerId}&callType=delivery_call&date=${this.data.date} `)
            .subscribe((res: any) => {
                if (res) {
                    this.anfAudioList = res || [];
                    this.showPop = true;
                }
            });
    }
}
