import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ListViewService } from '../../containers/list-view/list-view.service';
import { DataService } from '../../services/data/data.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: [ './card.component.scss' ]
})
export class CardComponent implements OnInit {
  @Input() _card;
  constructor (
    private router: Router,
    private listViewService: ListViewService,
    private dataService: DataService,
    public permissionsService: NgxPermissionsService
  ) { }

  ngOnInit () { }

  iconClicked (confirmation) {

    const messagesToBeShownInDialog = [];
    console.log(confirmation);
    confirmation.message.forEach((element) => {

      messagesToBeShownInDialog.push(element);
    })

    if (messagesToBeShownInDialog.length > 0) {
      const errorDialog = {
        message: messagesToBeShownInDialog,
        title: confirmation.title,
        why: confirmation.why
      };
      this.dataService.openDialogWithClass(errorDialog, 'mat_dialog_pad_24');
    }
  }

  performOperation (action) {
    if (action.deleteUrl || action.putUrl) {
      const dialogRef = this.dataService.openDialog(action);
      dialogRef.subscribe(result => {
        if (result) {
          if (action.deleteUrl) {
            this.listViewService
              .deleteData(action.deleteUrl, '')
              .subscribe(success => {
                if (action.privilege === 'delete_scheme') {
                  this.router.navigate([ action.redirectTo ]);
                } else {
                  this.dataService.refreshList.next(true);
                }
              });
          } else {
            this.listViewService
              .putData(action.putUrl, action.putParams)
              .subscribe(success => {
                this.dataService.refreshList.next(true);
              });
          }
        }
      });
    } else {
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate([ action.redirectTo ]));
    }
  }
}
