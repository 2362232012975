import {
  PageInfo,
  MasterDataValue,
  Crud,
} from '../../shared/interface/interface';
import { AppWebRoutes } from './app-web-routes';

interface AppConstant {
  monthNames: Array<string>;
  keyCodeNotAllowedForSuggestions: any;
  pages: AppPage;
  emailPattern: RegExp;
  phonePattern: RegExp;
  numberPattern: RegExp;
  negativeNumberPattern: RegExp;
  decimalNumberPattern: RegExp;
  twoDigitDecimalNumberPattern: RegExp;
  threeDigitDecimalNumberPattern: RegExp;
  positiveFourDigitNumber: RegExp;
  latLngPattern: RegExp;
  positiveNonDecimalNumber: RegExp;
  positiveNumberWithComma: RegExp;
  positiveOneDigitNumber: RegExp;
  positiveNumber: RegExp;
  codepushVersion: RegExp;
  phase1: Array<string>;
  phase2: Array<string>;
  menu: any;
  getDateByNumberOfDays: Function;
  productCustomString: Function;
  capitalizeAndRemoveUnderscore: Function;
  productCustomStringForSearch: Function;
  numberFixedDecimalString: Function;
  walletSplitDetailString: Function;
  masterData: MasterData;
  userContainer: Container;
  passwordContainer: Container;
  firebase_masterdata: any;
  positiveNegativeNumber: RegExp;
  subcategories: any;
  categories: any;
  bucketTypes: any;
  processTypes: any;
  cashbackReasonStatus: any;
  referImageKeys: Array<string>;
  hexCodePattern: RegExp;
  preCouponAmount: Function;
  couponCode: Function;
  couponDiscount: Function;
  preCouponAmountProduct: Function;
  couponDiscountProduct: Function;
}

interface AppPage {
  home: PageInfo;
  user: PageInfo;
  procurement: PageInfo;
  marketing: PageInfo;
  catalog: PageInfo;
  login: PageInfo;
  location: PageInfo;
  customer: PageInfo;
  pageNotFound: PageInfo;
  basket: PageInfo;
  common: PageInfo;
  ppd: PageInfo;
  forgotPassword: PageInfo;
  updatePassword: PageInfo;
  configuration: PageInfo;
  collection: PageInfo;
}

interface Container {
  crud?: Array<Crud>;
}

interface MasterData {
  HUB_TYPE: Array<MasterDataValue>;
  BANNER_LOCATION_TYPE: Array<MasterDataValue>;
  BANNER_TYPE: Array<MasterDataValue>;
  AUTH_USER_TYPE: Array<MasterDataValue>;
  CASH_COLLECTION_TYPE: Array<MasterDataValue>;
  TRANSACTION_STATUS: Array<MasterDataValue>;
  DAY: Array<MasterDataValue>;
  FREQUENCY: Array<MasterDataValue>;
  SCHEME_TYPE: Array<MasterDataValue>;
  CAMPAIGN_TYPE: Array<MasterDataValue>;
  TAXABLE: Array<MasterDataValue>;
  REFUND_TYPE: Array<MasterDataValue>;
  SHEET: Array<MasterDataValue>;
  STATUS: Array<MasterDataValue>;
  REASON: Array<MasterDataValue>;
  LOGIN_SOURCE: Array<MasterDataValue>;
  ORDER_STATUS: Array<MasterDataValue>;
  MSG_TEMPLATE_TYPE: Array<MasterDataValue>;
  MSG_TEMPLATE_MODULE: Array<MasterDataValue>;
  PRODUCT_STOCK_DAYS: Array<MasterDataValue>;
  RETRY_TYPE: Array<MasterDataValue>;
  DEVICE_OS: Array<MasterDataValue>;
  COLLECTION_TIME: Array<MasterDataValue>;
  CUSTOMER_SCHEME_STATUS: Array<MasterDataValue>;
  DISCOUNT_TYPE: Array<MasterDataValue>;
  PAYMENT_GATEWAY: Array<MasterDataValue>;
  TYPE: Array<MasterDataValue>;
  USER_TYPE: Array<MasterDataValue>;
  MEDIUM_CASH: Array<MasterDataValue>;
  MODE: Array<MasterDataValue>;
  MAX_CASHBACK_RATE: Array<MasterDataValue>;
  PAYMENT_MODE: Array<MasterDataValue>;
  SUPPORTED_PAYMENT_GATEWAY: Array<MasterDataValue>;
  CIMS_TICKET_SOURCE_TYPE: Array<MasterDataValue>;
  SCHEDULED_JOB: Array<MasterDataValue>;
  DB_LOGS_TYPE: Array<MasterDataValue>;
  PRODUCT_SCHEDULED_PRICE_DISCOUNT_STATUS: Array<MasterDataValue>;
  PRODUCT_SCHEDULED_PRICE_DISCOUNT_TYPE: Array<MasterDataValue>;
  PRODUCT_SOURCE: Array<MasterDataValue>;
  CUTOFF_TIME: Array<MasterDataValue>;
  REFILL_TYPE: Array<MasterDataValue>;
  OFFER_TEXT: Array<MasterDataValue>;
  CACHE_REFRESH: Array<MasterDataValue>;
  PAYMENT_OFFER_TYPE: Array<MasterDataValue>;
  PAYMENT_OFFER_CASHBACK_TYPE: Array<MasterDataValue>;
  EXTERNAL_PII_MASKED_GROUP: Array<MasterDataValue>;
  HUBS: any;
  CITIES: any;
  MASK_CUSTOMER_DETAIL_BY_GROUP: Array<MasterDataValue>;
  ACCOUNT_SUSPENSION: Array<MasterDataValue>;
  WALLET_FREEZE: Array<MasterDataValue>;
  OFFER_TEAM: Array<MasterDataValue>;
  OFFER_STATUS: Array<MasterDataValue>;
  TRANSPORT_SHIFT_LENGTH: Array<MasterDataValue>;
  CIMS_RETENTION_AOV_BUCKET: Array<MasterDataValue>;
  CIMS_RETENTION_ORDER_COUNT_BUCKET: Array<MasterDataValue>;
  CIMS_RETENTION_EMAIL_ID_MAPPING: Array<MasterDataValue>;
  CIMS_RETENTION_SUBCATEGORY: Array<MasterDataValue>;
  CIMS_RETENTION_AGENT: Array<MasterDataValue>;
}

export const appConstants: AppConstant = {
  userContainer: {
    crud: [
      {
        key: 'firstName',
        placeholder: 'First Name',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['First Name is required'],
        isReadOnly: false,
        heading: 'Admin Details',
      },
      {
        key: 'lastName',
        placeholder: 'Last Name',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['Last Name is required'],
        isReadOnly: false,
      },
    ],
  },
  passwordContainer: {
    crud: [
      {
        key: 'oldPassword',
        placeholder: 'Old Password',
        type: 'password',
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['old password is required'],
        isReadOnly: false,
        heading: 'Change Password',
      },
      {
        key: 'newPassword',
        placeholder: 'New Password',
        type: 'password',
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['First Name is required'],
        isReadOnly: false,
      },
      {
        key: 'confirmPassword',
        placeholder: 'Confirm password',
        type: 'password',
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['Last Name is required'],
        isReadOnly: false,
      },
    ],
  },

  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  keyCodeNotAllowedForSuggestions: [13, 37, 38, 39, 40],
  phase1: [
    'user',
    'common',
    'location',
    'marketing',
    'mastercatalog',
    'hubcatalog',
    'order',
    'basket',
    'procurement',
    'transaction',
    'customer',
    'configuration',
  ],
  phase2: [
    'user',
    'common',
    'location',
    'customer',
    'order',
    'basket',
    'marketing',
    'procurement',
    'transaction',
    'configuration',
  ],
  pages: {
    home: { icon: 'home', label: 'Home', path: '/home' },
    user: { icon: 'account_circle', label: 'User', path: '/user' },
    procurement: {
      icon: 'save_alt',
      label: 'Procurement',
      path: '/procurement',
    },
    marketing: {
      icon: 'equalizer',
      label: 'Marketing',
      path: '/marketing/invite-code',
    },
    customer: { icon: 'list_alt', label: 'Customer', path: '/customer/filter' },
    catalog: { icon: 'list_alt', label: 'Catalog', path: '/catalog' },
    login: { label: 'Login', path: '/login' },
    location: { icon: 'list_alt', label: 'Location', path: '/location' },
    basket: { icon: 'list_alt', label: 'Basket', path: '/basket' },
    common: {
      icon: 'list_alt',
      label: 'admin Common',
      path: '/admin-common/app-logs',
    },
    ppd: { icon: 'list_alt', label: 'PPD', path: '/ppd/delivery' },
    configuration: {
      icon: 'list_alt',
      label: 'Configuration',
      path: '/settings',
    },
    collection: {
      icon: 'list_alt',
      label: 'Collection',
      path: '/collection',
    },
    pageNotFound: { label: 'Page Not Found' },
    forgotPassword: { label: 'Forgot Password' },
    updatePassword: { path: '/update-password', label: 'Update Password' },
  },
  emailPattern:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  numberPattern: /^\d+$/,
  negativeNumberPattern: /^-?\d+$/,

  decimalNumberPattern: /^\d*\.?\d*$/,
  twoDigitDecimalNumberPattern: /^\d*?.\d{0,2}$/,
  threeDigitDecimalNumberPattern: /^\d*?.\d{0,3}$/,
  latLngPattern: /^\d{0,3}(\.\d{1,6})?$/,
  phonePattern: /^\d{10}$/,
  positiveNonDecimalNumber: /^[1-9]\d*$/,
  positiveNumber: /^[0-9]\d*$/,
  positiveNumberWithComma: /^((?!(0))\d{1,6})(,(?!(0))\d{1,6})*$/,
  positiveFourDigitNumber: /^([0-9]{4})$/,
  positiveOneDigitNumber: /^([1-3]{1})$/,
  codepushVersion: /^(\d{1,3}\.){3}\d{1,3}$/,
  positiveNegativeNumber: /^[+-]?[0-9]{1,9}(?:\.[0-9]{1,5})?$/,
  hexCodePattern: /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  subcategories: {},
  categories: {},
  bucketTypes: {
    arrayValues: [
      { value: 1, placeholder: 'SYSTEM-DRIVEN-WEEKLY' },
      { value: 2, placeholder: 'SYSTEM-DRIVEN-MONTHLY' },
      { value: 3, placeholder: 'TEMPORARY' },
      { value: 4, placeholder: 'PERMANENT(WEEKLY)' },
      { value: 5, placeholder: 'PERMANENT(MONTHLY)' },
    ],
    objectValues: {
      '1': 'SYSTEM-DRIVEN-WEEKLY',
      '2': 'SYSTEM-DRIVEN-MONTHLY',
      '3': 'TEMPORARY',
      '4': 'PERMANENT(WEEKLY)',
      '5': 'PERMANENT(MONTHLY)',
    },
  },
  processTypes: {
    arrayValues: [
      { value: 1, placeholder: 'Automatic' },
      { value: 2, placeholder: 'Manual' },
    ],
    objectValues: {
      '1': 'Automatic',
      '2': 'Manual',
    },
  },
  cashbackReasonStatus: {
    arrayValues: [
      { value: 0, placeholder: 'CREATED' },
      { value: 1, placeholder: 'APPROVED' },
      { value: 2, placeholder: 'DISABLED' },
      { value: 3, placeholder: 'EXHAUSTED' },
      { value: 4, placeholder: 'REJECTED' },
      { value: 5, placeholder: 'BUCKET_RENEWED' },
    ],
  },
  menu: {
    user: {
      root: {
        path: '',
        label: 'User Management',
        component: 'UserComponent',
        name: 'user',
        icon: 'portrait',
        active: false,
        privilege: ['view_user', 'view_privilege', 'view_group'],
        submenu: [
          {
            path: '/user',
            params: {},
            label: 'User List',
            privilege: 'view_user',
          },
          {
            path: '/user/privilege',
            params: {},
            label: 'Privilege',
            privilege: 'view_privilege',
          },
          {
            path: '/user/group',
            params: {},
            label: 'Group',
            privilege: 'view_group',
          },
          {
            path: '/user/user-incentive',
            params: {},
            label: 'User Incentive Report',
            privilege: 'upload_incentive',
          },
        ],
      },
    },
    search: {
      root: {
        path: '',
        label: 'Search',
        component: 'SearchComponent',
        name: 'search',
        icon: 'search',
        active: false,
        privilege: ['view_product', 'view_synonym'],
        submenu: [
          {
            path: '/search',
            params: {},
            label: 'Search',
            privilege: 'view_product',
          },
          {
            path: '/search/simulator',
            params: {},
            label: 'Search Simulator',
            privilege: 'view_product',
          },
          {
            path: '/search/result-redirection',
            params: {},
            label: 'Search result redirection',
            privilege: 'view_synonym',
          }
        ],
      },
    },
    marketing: {
      root: {
        path: '',
        label: 'Marketing',
        component: 'MarketingComponent',
        name: 'marketing',
        icon: 'equalizer',
        active: false,
        privilege: [
          'view_invite_code',
          'view_campaign',
          'view_membership',
          'view_banner',
          'view_scheme',
          'view_messages',
          'view_refers',
          'view_msg_template',
          'view_image',
        ],
        submenu: [
          {
            path: '/marketing/invite-code',
            params: {},
            label: 'Invite Code',
            privilege: 'view_invite_code',
          },
          {
            path: '/marketing/campaigns',
            params: {},
            label: 'Campaigns',
            privilege: 'view_campaign',
          },
          {
            path: '/marketing/recurring',
            params: {},
            label: 'Recurring Campaign',
            privilege: 'view_campaign',
          },
          {
            path: '/marketing/membership',
            params: {},
            label: 'Memberships',
            privilege: 'view_membership',
          },
          {
            path: '/marketing/society-ref',
            params: {},
            label: 'Society Referral',
            privilege: 'view_refers',
          },
          {
            path: '/marketing/banner',
            params: {},
            label: 'Banners',
            privilege: 'view_banner',
          },
          {
            path: '/marketing/scheme',
            params: {},
            label: 'Schemes',
            privilege: 'view_scheme',
          },
          {
            path: '/marketing/welcome-message',
            params: {},
            label: 'Welcome Message',
            privilege: 'view_messages',
          },
          {
            path: '/marketing/refer_list',
            params: {},
            label: 'Refer List',
            privilege: 'view_refers',
          },
          {
            path: '/marketing/message-templates',
            params: {},
            label: 'Message Templates',
            privilege: 'view_msg_template',
          },
          {
            path: '/marketing/image-upload',
            params: {},
            label: 'Image Upload',
            privilege: 'view_image',
          },
          {
            path: '/marketing/auto-sample-list',
            params: {},
            label: 'Auto Add Sample',
            privilege: 'view_catalog_offer',
          },
          {
            path: '/marketing/mb-pass',
            params: {},
            label: 'Mb Pass',
            privilege: 'mb_pass_view',
          },
          {
            path: '/marketing/offer/list',
            params: {},
            label: 'Offers & Cashbacks',
          },
          {
            path: '/marketing/coupon',
            params: {},
            label: 'Coupon',
            privilege: 'view_coupons',
          },
          {
            path: '/marketing/reward',
            params: {},
            label: 'Rewards',
            privilege: 'view_reward',
          },
          {
            path: '/marketing/flash-deals',
            params: {},
            label: 'Flash Deals',
            privilege: 'view_flash_deals',
          },
        ],
      },
    },
    location: {
      root: {
        path: '',
        label: 'Location',
        component: 'LocationComponent',
        name: 'location',
        icon: 'location_on',
        active: false,
        privilege: [
          'view_location',
          'view_city',
          'view_hub',
          'view_cluster',
          'view_society',
          'view_cluster',
          'view_ops_recce_requests',
        ],
        submenu: [
          {
            path: '/location',
            params: {},
            label: 'Location',
            privilege: 'view_location',
          },
          {
            path: '/location/map',
            params: {},
            label: 'Map',
            privilege: 'view_map',
          },
          {
            path: '/location/city',
            params: {},
            label: 'City',
            privilege: 'view_city',
          },
          {
            path: '/location/hub',
            params: {},
            label: 'Hubs',
            privilege: 'view_hub',
          },
          {
            path: '/location/clusters',
            params: {},
            label: 'Clusters',
            privilege: 'view_cluster',
          },
          {
            path: '/location/society',
            params: {},
            label: 'Societies',
            privilege: 'view_society',
          },
          {
            path: '/location/team',
            params: {},
            label: 'Team',
            privilege: 'view_cluster',
          },
          {
            path: '/location/team/clusters',
            params: {},
            label: 'Team Cluster',
            privilege: 'view_cluster',
          },
          {
            path: '/location/properties',
            params: {},
            label: 'Properties',
            privilege: 'view_city',
          },
          {
            path: '/location/cluster-deactivaction',
            params: {},
            label: 'Cluster Deactivation',
            privilege: 'view_society',
          },
          {
            path: '/location/recce',
            params: {},
            label: 'Ops Recce Requests',
            privilege: 'view_ops_recce_requests',
          },
        ],
      },
    },
    customer: {
      root: {
        path: '/customer/filter',
        label: 'Customer',
        component: 'CustomerFilterComponent',
        name: '/customer/filter',
        icon: 'people',
        active: false,
        privilege: [
          'view_customer',
          'view_segment',
          'view_segment',
          'view_customer_segment',
          'view_cims_retention',
        ],
        submenu: [
          {
            path: '/customer/filter',
            params: {},
            label: 'Customer Listing',
            privilege: 'view_customer',
          },
          {
            path: '/customer/segment/type',
            params: {},
            label: 'Segment type',
            privilege: 'view_segment',
          },
          {
            path: '/customer/segment/list',
            params: {},
            label: 'Segment',
            privilege: 'view_segment',
          },
          {
            path: '/customer/segment-list',
            params: {},
            label: 'Customer Segment',
            privilege: 'view_customer_segment',
          },
          {
            path: '/customer/retention-list',
            params: {},
            label: 'Retention Team',
            privilege: 'view_cims_retention',
          },
        ],
      },
    },
    collection: {
      root: {
        path: '',
        label: 'Collection',
        component: 'CollectionComponent',
        name: 'collection',
        icon: 'settings_applications',
        active: false,
        privilege: ['view_list', 'view_collection'],
        submenu: [
          {
            path: '/collection/list',
            params: {},
            label: 'List',
            privilege: 'view_list',
          },
          {
            path: '/collection/collection-list',
            params: {},
            label: 'Collection',
            privilege: 'view_collection',
          },
          {
            path: '/collection/one-pager',
            params: {},
            label: 'One Pager',
            privilege: 'view_collection',
          },
          {
            path: '/collection/brand-list',
            params: {},
            label: 'Brand',
            privilege: 'view_collection',
          },
          {
            path: '/collection/brand-launch',
            params: {},
            label: 'Brand Launch',
            privilege: 'view_collection',
          },
          {
            path: '/collection/sponsor',
            params: {},
            label: 'Sponsor',
            privilege: 'view_sponsor_type',
          },
        ],
      },
    },
    basket: {
      root: {
        path: '',
        label: 'Basket',
        component: 'BasketComponent',
        name: 'basket',
        icon: 'shopping_basket',
        active: false,
        submenu: [
          { path: '/basket', params: {}, label: 'Products' },
          // { path: '/basket/procurement', params: {}, label: 'Procurement' }
        ],
      },
    },
    order: {
      root: {
        path: '',
        label: 'Order',
        component: 'OrderComponent',
        name: 'order',
        icon: 'shopping_cart',
        active: false,
        privilege: ['view_order', 'view_recurring_order'],
        submenu: [
          {
            path: '/order/history',
            params: {},
            label: 'Orders',
            privilege: 'view_order',
          },
          {
            path: '/order/recurring',
            params: {},
            label: 'Recurring Orders',
            privilege: 'view_recurring_order',
          },
        ],
      },
    },
    ppd: {
      root: {
        path: '',
        label: 'PPD',
        component: 'PPDComponent',
        name: 'ppd',
        icon: 'local_shipping',
        active: false,
        submenu: [
          {
            path: '/ppd/delivery',
            params: {},
            label: 'Delivery',
            privilege: 'view_order',
          },
          {
            path: '/ppd/vehicles',
            params: {},
            label: 'Vehicles',
            privilege: 'view_vehicle',
          },
          {
            path: '/ppd/feature',
            params: {},
            label: 'Feature',
            privilege: 'view_feature',
          },
          {
            path: '/ppd/feature-activation',
            params: {},
            label: 'Feature Activation',
            privilege: 'view_feature',
          },
          {
            path: '/ppd/inventory',
            params: {},
            label: 'Inventory',
            privilege: 'view_inventory',
          },
          {
            path: '/ppd/return',
            params: {},
            label: 'Product Return',
            privilege: 'view_order',
          },
          {
            path: '/ppd/supply-chain',
            params: {},
            label: 'Supply Chain Tracking',
            privilege: 'view_cluster_milestone',
          },
          {
            path: '/ppd/anf',
            params: {},
            label: 'ANF',
            privilege: 'view_order',
          },
          {
            path: '/ppd/pps-sticker',
            params: {},
            label: 'PPS Sticker',
            privilege: 'view_order',
          },
          {
            path: '/ppd/transport',
            params: {},
            label: 'Transport Module',
            privilege: 'view_transport_vendor',
          },
          {
            path: '/ppd/picking-rollback',
            params: {},
            label: 'Picking Rollback',
            privilege: 'delete_inventory_out',
          },
          {
            path: '/ppd/eway',
            params: {},
            label: 'E waybill',
            privilege: 'view_order',
          },
          {
            path: '/ppd/ppd-banner',
            params: {},
            label: 'PPD Banners',
            privilege: 'view_ppd_banner',
          },
        ],
      },
    },
    procurement: {
      root: {
        path: '/procurement',
        label: 'Procurement',
        component: 'ProcurementComponent',
        name: 'procurement',
        icon: 'swap_horiz',
        privilege: ['view_procurement'],
        active: false,
      },
    },
    transaction: {
      root: {
        path: '',
        label: 'Transaction',
        component: 'SummaryCashbackComponent',
        name: 'transaction',
        icon: 'monetization_on',
        privilege: [
          'view_transaction',
          'view_transaction',
          'view_transaction',
          'view_cash_collection',
          'view_transaction',
          'view_transaction',
          'view_transaction',
          'view_cash_collection',
          'cashback_reason_view',
        ],
        active: false,
        submenu: [
          {
            path: '/transaction',
            params: {},
            label: 'All',
            privilege: 'view_transaction',
          },
          {
            path: '/transaction/summary',
            params: {},
            label: 'Summary',
            privilege: 'view_transaction',
          },
          {
            path: '/transaction/summarycashback',
            params: {},
            label: 'Cashback Summary',
            privilege: 'view_transaction',
          },
          {
            path: '/transaction/cashcollection',
            params: {},
            label: 'Cash Collection',
            privilege: 'view_cash_collection',
          },
          {
            path: '/transaction/milkcashback',
            params: {},
            label: 'Scheme Cashback',
            privilege: 'view_transaction',
          },
          {
            path: '/transaction/cashback',
            params: {},
            label: 'Cashback',
            privilege: 'view_transaction',
          },
          {
            path: '/transaction/onlinesummary',
            params: {},
            label: 'Online Summary',
            privilege: 'view_transaction',
          },
          {
            path: '/transaction/listcollection',
            params: {},
            label: 'List Collection',
            privilege: 'view_cash_collection',
          },
          {
            path: '/transaction/cashback-bucket',
            params: {},
            label: 'Cashback Bucket',
            privilege: 'cashback_reason_view',
          },
        ],
      },
    },
    selfhelp: {
      root: {
        path: '',
        label: 'SelfHelp',
        component: 'SelfHelpComponent',
        name: 'selfhelp',
        icon: 'shopping_cart',
        active: false,
        privilege: ['view_faq'],
        submenu: [
          {
            path: '/selfhelp/faq',
            params: {},
            label: 'FAQs',
            privilege: 'view_faq',
          },
        ],
      },
    },
    cims: {
      root: {
        path: '',
        label: 'CIMS',
        component: 'cimsComponent',
        name: 'cims',
        icon: 'addchart',
        active: false,
        privilege: ['view_cis_config'],
        submenu: [
          {
            path: '/cims/mapping',
            params: {},
            label: 'Group  Mapping',
            privilege: 'view_cis_config',
          },
          {
            path: '/cims/subcategory',
            params: {},
            label: 'CIMS Subcategory',
            privilege: 'view_cis_config',
          },
        ],
      },
    },
    common: {
      root: {
        path: '/common/app-logs',
        label: 'Common',
        component: 'AppLogsComponent',
        name: 'common',
        icon: 'view_list',
        active: false,
        privilege: [
          'view_user_log',
          'view_payment_option',
          'view_payment_offer',
          'codepush_version_view',
          'upload_s3',
        ],
        submenu: [
          {
            path: '/common/app-logs',
            params: {},
            label: 'Common',
            privilege: 'view_user_log',
          },
          {
            path: '/common/payments',
            params: {},
            label: 'Payment Options',
            privilege: 'view_payment_option',
          },
          {
            path: '/common/payment-method',
            params: {},
            label: 'Payment Methods',
            privilege: 'view_payment_option',
          },
          {
            path: '/common/payments-offers',
            params: {},
            label: 'Payment Offers',
            privilege: 'view_payment_offer',
          },
          {
            path: '/common/payment-offer-summary',
            params: {},
            label: 'Payment Offer Summary',
            privilege: 'view_customer_payment_offer',
          },
          {
            path: '/common/codepush-mngt/list',
            params: {},
            label: 'CodePush Management',
            privilege: 'codepush_version_view',
          },
          {
            path: '/common/upload-sample-csv',
            params: {},
            label: 'Upload Sample CSV',
            privilege: 'upload_s3',
          },
        ],
      },
    },
    hubcatalog: {
      root: {
        path: '',
        label: 'Hub Catalog',
        component: 'ProductComponent',
        name: 'catalog',
        icon: 'dashboard',
        active: false,
        privilege: [
          'view_category',
          'view_category',
          'view_category',
          'view_product',
          'view_vendor',
          'view_vendor',
          'view_brand',
          'view_brand',
          'view_product_tags',
          'view_product_hub_tags',
        ],
        submenu: [
          {
            path: '/catalog/super-category',
            params: {},
            label: 'Super Category',
            privilege: 'view_category',
          },
          {
            path: '/catalog/category',
            params: {},
            label: 'Category',
            privilege: 'view_category',
          },
          {
            path: '/catalog/sub-category',
            params: {},
            label: 'Sub Category',
            privilege: 'view_category',
          },
          {
            path: '/catalog',
            params: {},
            label: 'Product',
            privilege: 'view_product',
          },
          {
            path: '/catalog/vendor',
            params: {},
            label: 'Vendor',
            privilege: 'view_vendor',
          },
          {
            path: '/catalog/vendor/holiday',
            params: {},
            label: 'Vendor Holiday',
            privilege: 'view_vendor',
          },
          {
            path: '/catalog/brand',
            params: {},
            label: 'Brand',
            privilege: 'view_brand',
          },
          {
            path: '/catalog/tags',
            params: {},
            label: 'Tags',
            privilege: 'view_product_tags',
          },
          {
            path: '/catalog/product-tags',
            params: {},
            label: 'Product Tags Mapping',
            privilege: 'view_product_hub_tags',
          },
          {
            path: '/catalog/fv-stickers',
            params: {},
            label: 'F&V Stickers',
            privilege: 'view_product',
          },
          {
            path: '/catalog/product-sticker',
            params: {},
            label: 'Product Stickers',
            privilege: 'view_product',
          },
        ],
      },
    },
    mastercatalog: {
      root: {
        path: '',
        label: 'Master Catalog',
        component: 'ProductComponent',
        name: 'master',
        icon: 'dashboard',
        active: false,
        privilege: [
          'view_catalog',
          'view_category',
          'view_category',
          'view_category',
          'view_vendor',
        ],
        submenu: [
          {
            path: '/master/product-catalog',
            params: {},
            label: 'Product',
            privilege: 'view_catalog',
          },
          {
            path: '/master/super-category',
            params: {},
            label: 'Super Category',
            privilege: 'view_category',
          },
          {
            path: '/master/category',
            params: {},
            label: 'Category',
            privilege: 'view_category',
          },
          {
            path: '/master/sub-category',
            params: {},
            label: 'Sub Category',
            privilege: 'view_category',
          },
          {
            path: '/master/sub-category-type',
            params: {},
            label: 'Sub Category Type',
            privilege: 'view_category',
          },
          {
            path: '/master/p-type',
            params: {},
            label: 'P-type',
            privilege: 'view_category',
          },
          {
            path: '/master/vendor',
            params: {},
            label: 'Vendor',
            privilege: 'view_vendor',
          },
          {
            path: '/master/popular-suggestion',
            params: {},
            label: 'Popular Suggestion / Search Guide Text',
            privilege: 'view_synonym',
          },
          {
            path: '/master/product-variant',
            params: {},
            label: 'Product Variants',
            privilege: 'view_catalog',
          },
        ],
      },
    },
    configuration: {
      root: {
        path: '',
        label: 'Configuration',
        component: 'SettingsComponent',
        name: 'configuration',
        icon: 'settings_applications',
        active: false,
        submenu: [
          {
            path: '/configuration/api-settings',
            params: {},
            label: 'API Settings',
          },
          {
            path: '/configuration/properties',
            params: {},
            label: 'Properties',
          },
          {
            path: '/configuration/masterdata',
            params: {},
            label: 'Master Data',
          },
        ],
      },
    },
    wms: {
      root: {
        path: '',
        label: 'WMS',
        component: 'WareHouseManagementComponent',
        name: 'wms',
        icon: 'settings_applications',
        active: false,
        submenu: [
          {
            path: '/wms/stocks',
            params: {},
            label: 'wms-stock',
          },
          {
            path: '/wms/sales',
            params: {},
            label: 'wms-sales',
            privilege: 'generate_einvoice',
          },
          // { path: '/configuration', params: {}, label: 'APPS Settings', },
        ],
      },
    },
    ims: {
      root: {
        path: '',
        label: 'IMS',
        component: 'IMSComponent',
        name: 'ims',
        icon: 'shopping_cart',
        active: false,
        submenu: [
          {
            path: '/ims/master',
            params: {},
            label: 'Master',
            privilege: 'view_ims_product',
          },
          {
            path: '/ims/grn',
            params: {},
            label: 'IMS F&V',
            privilege: 'create_unsold_inventory',
          },
          {
            path: '/ims/inbound',
            params: {},
            label: 'Purchase Request',
            privilege: 'view_product_target',
          },
          {
            path: '/ims/purchase-allocation',
            params: {},
            label: 'Purchase Allocation',
            privilege: 'view_order',
          },
          {
            path: '/ims/raising-po/sku',
            params: {},
            label: 'Raise/ Receive PO',
            privilege: 'view_product_target',
          },
          {
            path: '/ims/recieve-po',
            params: {},
            label: 'GRN',
            privilege: 'view_product_target',
          },
          {
            path: '/ims/batching',
            params: {},
            label: 'Batching/ Crating',
            privilege: 'view_packing_target',
          },
          {
            path: '/ims/view-inventory',
            params: {},
            label: 'View Inventory',
            privilege: 'view_packing_target',
          },
          {
            path: '/ims/inventory/movement/request/today',
            params: {},
            label: 'Move Inventory',
            privilege: 'view_packing_target',
          },
          {
            path: '/ims/unsold-inventory',
            params: {},
            label: 'Unsold Inventory',
            privilege: 'view_packing_target',
          },
          {
            path: '/ims/outbound/filter/1',
            params: {},
            label: 'Packing Target',
            privilege: 'view_packing_target',
          },
          {
            path: '/ims/wastage',
            params: {},
            label: 'Wastage Mgmt',
            privilege: 'view_ims_product',
          },
        ],
      },
    },
    bdtracker: {
      root: {
        path: '',
        label: 'Bd Tracker',
        component: 'Bd-trackerComponent',
        name: 'bd-tracker',
        icon: 'settings_applications',
        active: false,
        submenu: [
          {
            path: '/bd-tracker/allocation',
            params: {},
            label: 'Allocation',
            privilege: 'view_bd_allocation',
          },
          {
            path: '/bd-tracker/pipeline',
            params: {},
            label: 'Pipeline',
            privilege: 'view_bd_allocation',
          },
          {
            path: '/bd-tracker/activation',
            params: {},
            label: 'Activation History',
            privilege: 'view_bd_allocation',
          },
          {
            path: '/bd-tracker/view-reports',
            params: {},
            label: 'View Reports',
            privilege: 'view_bd_allocation',
          },
          {
            path: '/bd-tracker/rwa-directory',
            params: {},
            label: 'Rwa Directory',
            privilege: 'view_bd_allocation',
          },
        ],
      },
    },
  },
  masterData: {
    HUB_TYPE: [],
    BANNER_LOCATION_TYPE: [],
    BANNER_TYPE: [],
    AUTH_USER_TYPE: [],
    CASH_COLLECTION_TYPE: [],
    TRANSACTION_STATUS: [],
    DAY: [],
    FREQUENCY: [],
    SCHEME_TYPE: [],
    CAMPAIGN_TYPE: [],
    TAXABLE: [],
    REFUND_TYPE: [],
    SHEET: [],
    STATUS: [],
    REASON: [],
    LOGIN_SOURCE: [],
    ORDER_STATUS: [],
    MSG_TEMPLATE_TYPE: [],
    MSG_TEMPLATE_MODULE: [],
    PRODUCT_STOCK_DAYS: [],
    RETRY_TYPE: [],
    DEVICE_OS: [],
    COLLECTION_TIME: [],
    CUSTOMER_SCHEME_STATUS: [],
    DISCOUNT_TYPE: [],
    PAYMENT_GATEWAY: [],
    TYPE: [],
    USER_TYPE: [],
    MEDIUM_CASH: [],
    MODE: [],
    MAX_CASHBACK_RATE: [],
    PAYMENT_MODE: [],
    SUPPORTED_PAYMENT_GATEWAY: [],
    CIMS_TICKET_SOURCE_TYPE: [],
    SCHEDULED_JOB: [],
    DB_LOGS_TYPE: [],
    PRODUCT_SCHEDULED_PRICE_DISCOUNT_TYPE: [],
    PRODUCT_SCHEDULED_PRICE_DISCOUNT_STATUS: [],
    PRODUCT_SOURCE: [],
    CUTOFF_TIME: [],
    REFILL_TYPE: [],
    OFFER_TEXT: [],
    CACHE_REFRESH: [],
    PAYMENT_OFFER_TYPE: [],
    PAYMENT_OFFER_CASHBACK_TYPE: [],
    HUBS: {},
    CITIES: {},
    EXTERNAL_PII_MASKED_GROUP: [],
    MASK_CUSTOMER_DETAIL_BY_GROUP: [],
    ACCOUNT_SUSPENSION: [],
    WALLET_FREEZE: [],
    OFFER_TEAM: [],
    OFFER_STATUS: [],
    TRANSPORT_SHIFT_LENGTH: [],
    CIMS_RETENTION_AOV_BUCKET: [],
    CIMS_RETENTION_ORDER_COUNT_BUCKET: [],
    CIMS_RETENTION_EMAIL_ID_MAPPING: [],
    CIMS_RETENTION_SUBCATEGORY: [],
    CIMS_RETENTION_AGENT: [],
  },
  referImageKeys: [
    'REFER_SHARE_IMAGE',
    'REFER_SHARE_IMAGE_0',
    'REFER_SHARE_IMAGE_1',
    'REFER_SHARE_IMAGE_2',
    'REFER_IMAGE_0',
    'REFER_IMAGE_1',
    'REFER_IMAGE_2',
  ],
  getDateByNumberOfDays: (numberOfDays) => {
    const date = new Date();
    date.setDate(date.getDate() + numberOfDays);
    return date;
  },
  productCustomStringForSearch: (row) => {
    const second = row.cutoffTime;
    const hours = second.substring(0, 2);
    const minute = second.substring(2);
    let cutOffMin = '';
    if (hours > 0) {
      const cutoffString = hours + ':' + minute;
      cutOffMin = '-cutOffTime(' + cutoffString + ')';
    }
    return `${row.productName}(${row.weight}) - MRP(${row.mrp}) - Price(${row.sellPrice}) ${cutOffMin}`;
  },
  /**
   *
   * @param stringVar
   *
   * takes a string ("hello_world") as input and outputs a string ("Hello World")
   * with underscore remove and words capitalized
   */
  capitalizeAndRemoveUnderscore: (str) => {
    return str
      .replace(/_{1,}/g, ' ')
      .replace(/(\s{1,}|\b)(\w)/g, (m, space, letter) => {
        return space + letter.toUpperCase();
      });
  },
  productCustomString: (row) => {
    return `${row.productName}
     <small class="d-block">Product Id : ${row.productId}</small>
     <small class="d-block">${
       row.productWeight ? '(' + row.productWeight + ')' : ''
     }
     ${row.productQuantity ? '- Qty = ' + row.productQuantity : ''}</small>
     <small class="d-block"><b>${
       row.categoryName !== undefined ? row.categoryName : ''
     }</b></small>
     <p class="d-block"><b>${row.recurring === 1 ? 'Recurring' : ''}</b></p>`;
  },
  numberFixedDecimalString: (row, rowMeta) => {
    return row[rowMeta.key].toFixed(2);
  },
  firebase_masterdata: {},
  walletSplitDetailString: (column) => {
    let resultant = [];
    let walletData;
    if (typeof column === 'string') {
      walletData = JSON.parse(column.replace('/', ''));
    } else if (typeof column === 'object') {
      walletData = column;
    }
    if (walletData) {
      'wallet' in walletData && (walletData = walletData.wallet);
      walletData?.main &&
        resultant.push(`Wallet: ${walletData.main.toFixed(2)}`);
      walletData?.food &&
        resultant.push(`Food Wallet: ${walletData.food.toFixed(2)}`);
      walletData?.cashback &&
        resultant.push(`Cashback: ${walletData.cashback.toFixed(2)}`);
    }
    return resultant.join('<br />');
  },
  preCouponAmount: (row) => {
    let resultant = 'N/A';
    let couponData;
    if (typeof row.data === 'string') {
      couponData = JSON.parse(row.data.replace('/', ''));
    } else if (typeof row.data === 'object') {
      couponData = row.data;
    }
    if (couponData) {
      'coupon' in couponData && (couponData = couponData.coupon);
      if (couponData?.discount) {
        const orderAmount = row.amount + couponData.discount;
        resultant = `${orderAmount.toFixed(2)}`;
      }
    }
    return resultant;
  },
  couponCode: (row) => {
    let resultant = 'N/A';
    let couponData;
    if (typeof row.data === 'string') {
      couponData = JSON.parse(row.data.replace('/', ''));
    } else if (typeof row.data === 'object') {
      couponData = row.data;
    }
    if (couponData) {
      'coupon' in couponData && (couponData = couponData.coupon);
      if (couponData?.name) {
        resultant = couponData?.name;
      }
    }
    return resultant;
  },
  couponDiscount: (row) => {
    let resultant = 'N/A';
    let couponData;
    if (typeof row.data === 'string') {
      couponData = JSON.parse(row.data.replace('/', ''));
    } else if (typeof row.data === 'object') {
      couponData = row.data;
    }
    if (couponData) {
      'coupon' in couponData && (couponData = couponData.coupon);
      if (couponData?.discount) {
        resultant = `${couponData?.discount.toFixed(2)}`;
      }
    }
    return resultant;
  },
  preCouponAmountProduct: (row) => {
    let resultant = 'N/A';
    if (row?.data?.couponDiscount) {
      const couponDiscountPerUnit =
        row.data.couponDiscount / row.productQuantity;
      const productAmount = row.pricePerUnit + couponDiscountPerUnit;
      resultant = `${productAmount.toFixed(2)}`;
    }
    return resultant;
  },
  couponDiscountProduct: (row) => {
    let resultant = 'N/A';
    if (row?.data?.couponDiscount) {
      const couponDiscountPerUnit =
        row.data.couponDiscount / row.productQuantity;
      resultant = `${couponDiscountPerUnit.toFixed(2)}`;
    }
    return resultant;
  },
};
export const globalFilter = [
  {
    key: 'cityId',
    placeholder: 'City',
    type: 'autocomplete',
    apiEndPoint: AppWebRoutes.CITIES.cities,
    validations: { required: true },
    validationMessages: ['City is required'],
    chip: true,
    likeSearch: false,
    targetIndex: 1,
    target: 'hubId',
    action: 'auto_update_dependent_list',
    searchableDropDown: true,
    options: [],
    chipOptions: {},
    hintLabel: '',
    uiKey: 'name',
    apiKey: 'id',
    globalFilter: true,
    module: 'crud',
  },
  {
    key: 'hubId',
    placeholder: 'Hub',
    type: 'autocomplete',
    apiEndPoint: AppWebRoutes.HUBS.hubs,
    optionsParams: { active: 1 },
    defaultValue: '',
    validations: { required: true },
    validationMessages: ['Hub is required'],
    isReadOnly: false,
    uiKey: 'name',
    apiKey: 'id',
    searchableDropDown: true,
    chipOptions: {},
    chip: false,
    module: 'crud',
    filterWithHubCity: '',
    dependent: true,
    globalFilter: true,
  },
];
export enum PromocodeTypes {
  SUBCATEGORY_SCHEME = 'SUBCATEGORY_SCHEME',
  CASH_IN_WALLET_SCHEME = 'CASH_IN_WALLET_SCHEME',
  TOPUP_CASHBACK = 'TOPUP_CASHBACK',
  WELCOME_CASHBACK = 'WELCOME_CASHBACK',
  SAMPLE_LIST_PRODUCT = 'SAMPLE_LIST_PRODUCT',
  MB_PASS = 'MB_PASS',
  BD_TRACKER = 'BD_TRACKER',
  PRODUCT_SCHEME = 'PRODUCT_SCHEME',
  ADD_TO_SEGMENT = 'ADD_TO_SEGMENT',
}

export enum CampaignTypes {
  AUTO_ADD_SAMPLE = 'AUTO_ADD_SAMPLE',
  CASH_IN_WALLET_UNPAID = 'CASH_IN_WALLET_UNPAID',
  MAHA_CASHBACK = 'MAHA_CASHBACK',
  PAID = 'CASH_IN_WALLET_PAID',
  CASH_IN_WALLET_PAID = 'CASH_IN_WALLET_PAID',
  MEMBERSHIP_DISCOUNT = 'MEMBERSHIP_DISCOUNT',
  DIFFERENTIAL_PRICING = 'DIFFERENTIAL_PRICING',
}

export enum PromoLocationType {
  All = '1',
  City = '2',
  Hub = '3',
  Society = '4',
  Segment = '5',
}

export enum LocationType {
  City = 'City',
  Hub = 'Hub',
  Society = 'Society',
}

export enum CashbackApplicability {
  Fixed = '1',
  Percentage = '2',
}

export const CCAction = {
  Approved: 20,
  Declined: 21,
};

export enum MediaExtension {
  PNG = 'png',
  JPG = 'jpg',
  JPEG = 'jpeg',
  MP4 = 'mp4',
  JSON = 'json',
}

export enum MediaType {
  VIDEO = 1,
  IMAGE,
  LOTTIE,
  DOCUMENT,
}
export enum CollectionDisplayType {
  NORMAL_COLLECTION = 1,
  CATEGORY_WIDGET_COLLECTION = 2,
  VIDEO_COLLECTION = 12,
  THREE_COLUMN_IN_A_ROW_COLLECTION = 13,
  ON_SCROLL_COLLECTION = 14,
  LOTTIE_COLLECTION = 15,
}
